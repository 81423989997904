import React from "react";

const AutoAnimationScroll = ({ images, reverse }) => {
  return (
    <div className="relative overflow-hidden select-none w-full max-w-8xl">
      {/* Gradient overlay for fading effect on edges */}
      <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black pointer-events-none z-10"></div>

      {/* Animated scrolling container */}
      <div
        className={`flex ${reverse ? "animate-scrollXReverse" : "animate-scrollX"} space-x-4`}
        style={{ width: `${images.length * 220 * 2}px` }} // Dynamic width for seamless scrolling
      >
        {[...images, ...images].map((el, index) => (
          <div
            key={index}
            className="grid place-items-center w-[clamp(150px,20vw,220px)]"
          >
            <img
              src={el}
              alt={`Logo ${index + 1}`}
              className="object-contain w-full  h-[120px] lg:h-[200px] rounded-lg shadow-lg"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoAnimationScroll;

// chatSlice.js

import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatMessages: [],
  },
  reducers: {
    setChatMessages: (state, action) => {
      state.chatMessages = action.payload;
    },
    addChatMessage: (state, action) => {
      state.chatMessages.push(action.payload);
    },
    clearChatMessages: (state, action) => {
      state.chatMessages = []
    },
  },
});

export const { setChatMessages, addChatMessage,clearChatMessages } = chatSlice.actions;
export default chatSlice.reducer;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const PaymentCancelPage = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate('/pricing'); // Redirect to the subscription plans page to retry payment
  };

  const handleGoHome = () => {
    navigate('/'); // Redirect to the homepage
  };

  return (
    <>
      <Header />

      <div className="min-h-screen flex flex-col items-center justify-center bg-black px-4">
        <div className="bg-gray-800 border border-gray-600 shadow-lg rounded-lg p-6 max-w-md w-full text-center">
          {/* <div className="flex justify-center mb-4">
            <CancelIcon className="h-16 w-16 text-red-600" />
          </div> */}
          <h2 className="text-2xl font-semibold text-red-500 mb-4">
            Payment Canceled
          </h2>
          <p className="text-gray-300 mb-6">
            Your payment was canceled. Don't worry, you can retry the process or explore other plans we have available.
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-4">
            <button
              onClick={handleRetry}
              className="w-full sm:w-auto bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition duration-300"
            >
              Retry Payment
            </button>
            <button
              onClick={handleGoHome}
              className="w-full sm:w-auto bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition duration-300"
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCancelPage;

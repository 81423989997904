import React, { useEffect, useState } from "react";
import { GlobalOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const LanguageDropdown = ({ isFullWidth, openDirection = "down" }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem("language") || "fr");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageChange = (value) => {
    setLanguage(value);
    localStorage.setItem("language", value);
    i18n.changeLanguage(value);
    setIsOpen(false);
  };

  const languages = [
    { value: "en", label: "English" },
    { value: "fr", label: "Français" },
    { value: "hi", label: "हिन्दी" },
    // Add more languages as needed
  ];

  return <></>
   
  return (
    <div className={`hidden lg:block relative ${isFullWidth && "w-full"}`}>
      <button
        className={`flex items-center space-x-2 ${isFullWidth && "w-full"} text-white bg-gray-800 px-4 py-2 rounded-md`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <GlobalOutlined />
        <span>{languages.find((lang) => lang.value === language)?.label}</span>
      </button>
      {isOpen && (
        <div
          className={`absolute ${openDirection === "up" ? "bottom-12" : "top-12"} left-0 w-full bg-gray-900 border border-gray-700 rounded-md shadow-lg z-50`}
        >
          {languages.map((lang) => (
            <button
              key={lang.value}
              onClick={() => handleLanguageChange(lang.value)}
              className={`w-full text-left px-4 py-2 text-white hover:bg-gray-800 ${
                lang.value === language ? "bg-gray-800" : ""
              }`}
            >
              {lang.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;

// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCvEIKBTmU4xDvxk7wUSEl4rJZ6WR4AwVs",
  authDomain: "sumrlyai.firebaseapp.com",
  databaseURL: "https://sumrlyai-default-rtdb.firebaseio.com",
  projectId: "sumrlyai",
  storageBucket: "sumrlyai.appspot.com",
  messagingSenderId: "69547907492",
  appId: "1:69547907492:web:fe9eb0b3c354ad4fc44931",
  measurementId: "G-6W91T04KCX",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };

import axios from "axios";
import { API } from "../apikeys";

// Helper function to get the user ID from local storage
const getUserId = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.user?.id; // Ensure you're accessing the correct property for the ID
};

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor to set the header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const userId = getUserId();
    if (userId) {
      config.headers["X-User-Id"] = userId; // Set the user ID from local storage as a header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// generateMAQ
export const generateMAQ = async (data) => {
  try {
    const response = await axiosInstance.post(`${API}/mostAskedQuestions`, data);
    return response.data?.questions || []
  } catch (error) {
    console.error("Error maq:", error);
    return(error.response.data);
  }
};
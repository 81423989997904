import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchAllPlans } from "../apis/planAPI";
import { getUserId } from "../utils/utils";
import { API } from "../apikeys";
import { Spin } from "antd";
import { planData } from "../data/plans";
import SubscribersIconDark from "../assets/SubscribersIconDark.png"
import Spinner from "./Spinner";

const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-check w-5 h-5 mr-2 text-green-500"
  >
    <path d="M20 6 9 17l-5-5"></path>
  </svg>
);

const UpgradeModal = ({ visible, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isAnnual, setIsAnnual] = useState(true);
  const [isBuying, setIsBuying] = useState(false);

  useEffect(() => {
    const loadPlans = async () => {
      try {
        const fetchedPlans = await fetchAllPlans();
        const filteredPlans = fetchedPlans.filter(
          (plan) => plan.monthlyPrice > 0 && plan.ln === "fr"
        );
        const sortedPlans = filteredPlans.sort(
          (a, b) => b.monthlyPrice - a.monthlyPrice
        );

        setPlans(sortedPlans);
        setSelectedPlan(sortedPlans[0]);
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    if (visible) {
      loadPlans();
    }
  }, [visible]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.id === "modal-overlay") {
        onClose();
      }
    };

    if (visible) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [visible, onClose]);


  const handleCheckout = async () => {
    const userId = getUserId();
    setIsBuying(true);
    try {
      const response = await fetch(`${API}/payments/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ planId:selectedPlan?.id, userId, isYearly:isAnnual }),
      });

      const { url } = await response.json();
      window.location.href = url; // Redirect to the Stripe checkout page
      onClose();

      setIsBuying(false);
    } catch (error) {
      console.error("Error creating checkout session:", error);

      setIsBuying(false);
    }
  };

  const handleUpgrade = () => {
    handleCheckout()
  };

  if (!visible || !selectedPlan) return null;

  return (
    <div
      id="modal-overlay"
      className="fixed p-4 inset-0 bg-black bg-opacity-75 backdrop-blur-md flex items-center justify-center z-50"
    >
      <div className="bg-[#0a0a0a] p-12 border border-gray-700 text-white rounded-lg max-w-lg w-full shadow-lg relative transform transition-transform duration-300 scale-100">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-300 focus:outline-none text-2xl"
        >
          &times;
        </button>

        <h3 className="text-xl font-semibold mb-2">
          {t("upgradeModal.title", "Unlock full access")}
        </h3>
        <p className="text-gray-400 mb-4">
          {t(
            "upgradeModal.subtitle",
            "Choose the plan that best fits your needs"
          )}
        </p>

        {/* Tabs for Plans */}
        <div className="flex items-center bg-[#1c1c1c] rounded-full p-1 mb-6">
          {plans.map((plan) => (
            <button
              key={plan.id}
              onClick={() => setSelectedPlan(plan)}
              className={`flex-1 py-2 rounded-full font-semibold transition-colors duration-200 ${
                selectedPlan.id === plan.id
                  ? "bg-[#0a0a0a] text-white"
                  : "bg-transparent text-gray-400"
              }`}
            >
              {plan.name}
            </button>
          ))}
        </div>

        {/* Feature List with Checkmark SVG */}
        <ul className="space-y-3 mb-8">
          {selectedPlan.features.map((feature, index) => (
            <li key={index} className="flex text-sm items-center text-gray-300">
              <CheckmarkIcon />
              {feature}
            </li>
          ))}
        </ul>

        {/* Annual vs Monthly Pricing - Styled Buttons */}
        <div className="flex flex-col items-center space-y-3 mb-6">
          {/* Annual Button */}
          <button
            onClick={() => setIsAnnual(true)}
            className={`w-full flex items-center justify-between px-4 py-3 font-medium rounded-xl border transition-colors duration-200 ${
              isAnnual
                ? "border-customPink bg-[#101d13] text-gray-100 shadow-inner"
                : "border-gray-700 bg-transparent text-gray-100"
            }`}
          >
            <div className="text-left flex items-center">
              {t("upgradeModal.annual", "Annual")}
              {selectedPlan.discount > 0 && (
                <span class="ml-2 text-green-500 border-customPink border px-2 py-1 rounded-full text-xs">
                  Économisez {selectedPlan.discount}%
                </span>
              )}
            </div>
            <span className="text-xl">{selectedPlan.yearlyPrice}€ {t(
              "subscriptionPlans.perMonthAnnually"
            )}</span>
          </button>

          {/* Monthly Button */}
          <button
            onClick={() => setIsAnnual(false)}
            className={`w-full flex items-center justify-between px-4 py-3 font-medium rounded-xl border transition-colors duration-200 ${
              !isAnnual
                ? "border-customPink bg-[#101d13] text-gray-100 shadow-inner"
                : "border-gray-700 bg-transparent text-gray-100"
            }`}
          >
            <div className="text-left">
              {t("upgradeModal.monthly", "Monthly")}
            </div>
            <span className="text-xl">{selectedPlan.monthlyPrice}€ {t(
              "subscriptionPlans.perMonth"
            )}</span>
          </button>
        </div>

        {/* Upgrade Button */}
        <button
        disabled={isBuying}
          onClick={handleUpgrade}
          className="w-full py-3 bg-white text-black rounded-full font-semibold hover:bg-gray-300 transition"
        >
         {isBuying? <Spinner /> : <> {selectedPlan.buttonText ||
            t("upgradeModal.choosePlan", "Choose Plan")}</>}
        </button>

        {/* Footer with Count */}
        <footer className="mt-6 flex items-center  text-xs text-gray-500">
        <img src={SubscribersIconDark}  className="mr-2 w-16 h-20 object-contain" />  

        <p>
        {t(
            "upgradeModal.footer",
            "Join 16,731 learners studying smarter with YouLearn"
          )}

        </p>
        </footer>
      </div>
    </div>
  );
};

export default UpgradeModal;

// VideoCard.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllSpaces } from "../apis/spaceAPI";
import Spinner from "./Spinner";
import DropdownMenu from "./DropdownMenu";
import { clearRedux, getAllThreadsByVdId } from "../apis/assistantApi";
import { setSpaces } from "../redux/homeSlice";
import PreviewPdf from "../assets/preview_pdf_file.png"

export default function VideoCard({ item }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  
  const getAllDetails = async (videoId) => {
    if (!dropdownVisible) {
      setLoading(true);
      clearRedux(dispatch)
      // await getAllThreadsByVdId(videoId, dispatch);
      setLoading(false);
    
      navigate(`/video/${videoId}?type=${item?.type||"YOUTUBE"}`);
    }
  };

  const refreshHistory = async () => {
    // Fetch updated spaces or history after deletion
    const data = await fetchAllSpaces();
    dispatch(setSpaces(data));
  };

  return (
    <div
      key={item.id}
      className="relative border border-gray-700 bg-customGray rounded-lg p-2 group overflow-hidden cursor-pointer"
      onClick={() => getAllDetails(item.videoId)}
    >
      {/* Dropdown Button - Visible only on hover */}
      <div
        className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"
        onMouseEnter={() => setDropdownVisible(true)}
        onMouseLeave={() => setDropdownVisible(false)}
      >
        <DropdownMenu
          videoId={item.videoId}
          historyId={item.id}
          videoUrl={`${window.location.origin}/video/${item.videoId}`}
          refreshHistory={refreshHistory} // Pass refresh function
        />
      </div>

      {/* Video Thumbnail */}
      {loading ? (
        <div className="w-full h-40 object-cover rounded-lg border border-gray-700 flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <img
          src={item.thumbnailUrl || PreviewPdf}
          alt={item.title}
          className="w-full h-40 object-cover rounded-lg border border-gray-700 mb-4 transition-transform duration-300 group-hover:scale-105"
        />
      )}

      {/* Video Title */}
      <h3 className="text-sm font-medium truncate px-2 mb-2">{item.title}</h3>

      {/* Green shadow effect on hover */}
      <div className="absolute bottom-0 left-0 right-0 h-2 bg-gradient-to-r from-transparent via-customPink to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
    </div>
  );
}

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import VideoPage from "./pages/VideoPage";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { Provider, useDispatch } from "react-redux";
import { store } from "./redux/store"; // import store
import HistoryPage from "./pages/HistoryPage";
import Pricing from "./pages/Pricing";
import PaymentPage from "./pages/PaymentPage";
import SuccessPage from "./pages/SuccessPage";
import PaymentCancelPage from "./pages/PaymentCancelPage";
import { useEffect } from "react";
import { checkPlanExpiry } from "./apis/planAPI";
import Space from "./pages/Space";
import Settings from "./pages/Settings";
import './i18n'; // Import the i18n configuration

function App() {

  useEffect(() => {

  
    
    const checkExpiry = async () => {
      try {
        await checkPlanExpiry();
        console.log("Plan expiry checked successfully");
      } catch (error) {
        console.error("Error checking plan expiry:", error);
      }
    };


    checkExpiry(); // Call the expiry check function when the app loads
  }, []); // Empty dependency array to run only once when the component mounts

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Login Route */}
          <Route path="/login" element={<LoginPage />} />

          {/* Protected Routes */}

          <Route
            path="/history"
            element={
              <ProtectedRoute>
                <HistoryPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/space/:spaceId"
            element={
              <ProtectedRoute>
                <Space />
              </ProtectedRoute>
            }
          />

          <Route
            path="/cancel"
            element={
              <ProtectedRoute>
                <PaymentCancelPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/success"
            element={
              <ProtectedRoute>
                <SuccessPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/payment"
            element={
              <ProtectedRoute>
                <PaymentPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/pricing"
            element={
              <ProtectedRoute>
                <Pricing />
              </ProtectedRoute>
            }
          />


<Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route
            path="/history"
            element={
              <ProtectedRoute>
                <HistoryPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/video/:videoId"
            element={
              <ProtectedRoute>
                <VideoPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;

import React from 'react';

const SkeletonCard = () => (
  <div className="w-full h-32 bg-customGray rounded-lg animate-pulse"></div>
);

const SkeletonSectionTitle = ({ text }) => (
  <div className="flex justify-between items-center mb-4">
    <div className="h-6 w-1/2 bg-customGray rounded animate-pulse"></div>
    {text === "Voir plus" && <div className="h-6 w-16 bg-customGray rounded animate-pulse"></div>}
  </div>
);

const SkeletonButton = () => (
  <div className="w-full h-12 bg-customGray rounded-lg flex items-center justify-center animate-pulse">
    <div className="h-6 w-6 bg-customGray rounded-full mr-2"></div>
    <div className="w-1/3 h-4 bg-customGray rounded animate-pulse"></div>
  </div>
);

const SkeletonDashboard = () => {
  return (
    <div className="bg-black text-customGray p-8  ">
      {/* Watch History Section */}
      <SkeletonSectionTitle text="Voir plus" />
      <div className="grid grid-cols-4 gap-4 mb-8">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>

      {/* My Spaces Section */}
      <SkeletonSectionTitle text="Mes Espaces" />
      <div className="grid grid-cols-4 gap-4 mb-8">
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
      </div>

      {/* Explore Topics Section */}
      <SkeletonSectionTitle text="Explorer les Sujets" />
      <div className="grid grid-cols-3 gap-4 mb-8">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>
    </div>
  );
};

export default SkeletonDashboard;

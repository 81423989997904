import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  videoId: null,
  transcript: null,
  summary: null,
  chapters: null,
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setVideoId: (state, action) => {
      state.videoId = action.payload;
    },
    setTranscript: (state, action) => {
      state.transcript = action.payload;
    },
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    setChapters: (state, action) => {
      state.chapters = action.payload;
    },
    clearVideoData: (state) => {
      state.videoId = null;
      state.transcript = null;
      state.summary = null;
      state.chapters = null;
    },
  },
});

export const { setVideoId, setTranscript, setSummary, setChapters, clearVideoData } = videoSlice.actions;

export default videoSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  history: [],
  spaces: [],
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHistory(state, action) {
      state.history = action.payload;
    },
    setSpaces(state, action) {
      state.spaces = action.payload;
    },
  },
});

export const { setHistory, setSpaces } = homeSlice.actions;

export default homeSlice.reducer;
// PaymentPage.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API, STRIPE_SECRET_KEY } from '../apikeys';

const stripePromise = loadStripe("STRIPE_SECRET_KEY"); // Replace with your Stripe publishable key

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe or Elements not loaded');
      return;
    }

    setLoading(true);

    // Confirm the payment using the mounted PaymentElement
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin, // Optional: redirect after payment
      },
    });

    if (error) {
      setMessage(`Payment failed: ${error.message}`);
    } else {
      setMessage('Payment successful!');
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center">
      {/* Render the PaymentElement */}
      <PaymentElement options={{ layout: 'tabs' }} />
      <button
        type="submit"
        disabled={loading || !stripe}
        className="bg-blue-600 text-white px-4 py-2 mt-4 rounded-lg hover:bg-blue-700 transition"
      >
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
      {message && <p className="mt-4 text-red-500">{message}</p>}
    </form>
  );
};

const PaymentPage = () => {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // Create a PaymentIntent when the component mounts
    fetch(`${API}/create-payment-intent`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount: 1000, currency: 'usd' }), // Adjust the amount & currency as needed
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((error) => console.error('Error fetching payment intent:', error));
  }, []);

  // Only render the Elements component once the clientSecret is available
  if (!clientSecret) {
    return <div className="min-h-screen flex items-center justify-center bg-gray-100">Loading...</div>;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default PaymentPage;



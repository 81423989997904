import { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  addChatMessageToThread,
  getAllThreads,
  runThreadForChat,
} from "../apis/assistantApi";
import MarkdownPreview from "./MarkdownPreview";
import Spinner from "./Spinner";
import UpgradeModal from "../components/UpgradeModal";
import SkeletonChat from "./skeleton/SkeletonChat";
import { useParams } from "react-router-dom";
import { fetchChats } from "../apis/youtubeAPI";
import { useSelector } from "react-redux";

const SpaceChat = ({ spaceDetails }) => {
  const { spaceId } = useParams();
  const [visible, setVisible] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRender, setLoadingRender] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const chatContainerRef = useRef(null);
  const chatBoxRef = useRef(null);

  const assistants = useSelector((state) => state.assistant?.assistants);
  const chatBotAssistant = assistants?.find(
    (assistant) => assistant.type === "SPACE_CHAT"
  );
  let assistantId = chatBotAssistant?.assistantId

  // Initialize chatbot thread
  useEffect(() => {
    const initializeChatThread = async () => {
      const res = await getAllThreads(spaceId);
      if (res?.length > 0) {
        setThreadId(res[0]?.threadId);
      }
    };
    initializeChatThread();
  }, [spaceId]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    if (visible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visible]);

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await fetchChats(spaceId);
        if (response?.chats) {
          setChatMessages(response.chats);
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
        setLoadingRender(false);
      }
    };
    fetchChatHistory();
  }, [spaceId]);

  const pollForChatResponse = async (userMessage, messageId) => {
    if (!threadId) return;
    try {
      const response = await runThreadForChat(
        threadId,
        spaceDetails?.id,
        assistantId, // Pass the assistantId prop directly
        userMessage,
        messageId
      );

      if (response.status === "completed") {
        const assistantMessage = {
          role: "assistant",
          content: response.response,
        };
        setChatMessages((prevMessages) => [...prevMessages, assistantMessage]);
        setLoading(false);
      } else {
        setTimeout(() => pollForChatResponse(userMessage, messageId), 1500); // Poll every 1.5 seconds
      }
    } catch (error) {
      console.error("Error polling assistant response:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (_chatInput) => {
    if (loading || loadingRender) return;
    const _value = _chatInput || chatInput;
    if (_value?.trim()) {
      setLoading(true);
      const userMessage = { role: "user", content: _value.trim() };
      setChatMessages((prevMessages) => [...prevMessages, userMessage]);
      setChatInput("");

      try {
        const msgData = await addChatMessageToThread(threadId, {
          transcript: _value.trim(),
          videoId: spaceDetails?.id,
          type: "CHAT_BOT",
        });

        pollForChatResponse(_value.trim(), msgData?.messageId);
      } catch (error) {
        console.error("Error handling chat:", error);
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (loading || loadingRender) return;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const onModelHandler = () => {
    setVisible(!visible);
  };

  return (
    <>
      <button
        onClick={onModelHandler}
        className="bg-customGray border border-gray-700 text-white rounded-lg px-4 py-1 hover:bg-gray-700 transition"
      >
        Chat Space
      </button>

      {visible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm">
          <div
            ref={chatBoxRef}
            className="relative bg-black text-white p-4 rounded-xl max-w-5xl w-full shadow-lg border border-gray-700 animate-zoom-in"
          >
            <button
              className="absolute top-2 right-2 text-gray-300 hover:text-white"
              onClick={onModelHandler}
            >
              <CloseOutlined />
            </button>
            <div className="flex flex-col h-[85vh]">
              <div className="mb-4 text-lg font-semibold text-center text-gray-100">
                {spaceDetails?.name || "Untitled Space"}
              </div>
              <div
                className="flex-1 overflow-y-auto bg-black rounded-md p-4 space-y-4 mb-10"
                ref={chatContainerRef}
              >
                {loadingRender ? (
                  <Spinner />
                ) : chatMessages.length === 0 ? (
                  <div className="text-center text-gray-500 min-h-[85%] flex items-center justify-center">
                    No messages yet. Start a conversation!
                  </div>
                ) : (
                  chatMessages.map((message, index) => (
                    <div
                      key={index}
                      className={`mb-4 ${
                        message.role === "user" ? "text-right" : "text-left"
                      }`}
                    >
                      <div
                        className={`inline-flex p-2 rounded-lg w-auto max-w-[80%] ${
                          message.role === "user"
                            ? "bg-customGray border border-gray-600 text-gray-200 text-left"
                            : "border border-gray-700 text-gray-300 text-sm font-light"
                        }`}
                      >
                        {message.role === "user" ? (
                          message.content
                        ) : (
                          <MarkdownPreview responseText={message.content} />
                        )}
                      </div>
                    </div>
                  ))
                )}

                {loading && (
                  <div className="text-center py-4">
                    <SkeletonChat />
                  </div>
                )}
              </div>

              <div className="absolute bottom-0 left-0 right-0">
                <div className="px-4 pb-2 border-gray-700 ">
                  <div className="flex items-center space-x-2 border border-gray-600 rounded-full px-2">
                    <textarea
                      className="flex-grow p-4 bg-transparent outline-none rounded-lg text-white focus:outline-none resize-none"
                      rows="1"
                      placeholder="Start chat here..."
                      value={chatInput}
                      onChange={(e) => setChatInput(e.target.value)}
                      onKeyDown={handleKeyPress}
                    ></textarea>
                    <button
                      disabled={loading || loadingRender}
                      onClick={handleSubmit}
                      className="bg-gray-900 text-white px-4 py-1 my-1 rounded-full disabled:opacity-50"
                    >
                      {loading || loadingRender ? <Spinner /> : "Send"}
                    </button>
                  </div>
                </div>
              </div>

              <UpgradeModal
                visible={showUpgradeModal}
                message="Please upgrade to access more features."
                onClose={() => setShowUpgradeModal(false)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SpaceChat;

/* Tailwind CSS Custom Animations */
<style jsx global>{`
  .animate-zoom-in {
    animation: zoomIn 0.4s ease-out;
  }

  @keyframes zoomIn {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`}</style>;

// videoData.js

export const videos = [
    {
      id: "O3tic0Vqh7s",
      title: "Trouver le déterminant d’une matrice - Khan Academy FR",
      duration: "4:44 min",
      imageUrl: "https://i.ytimg.com/vi/O3tic0Vqh7s/hq720.jpg",
      link: "https://youtu.be/O3tic0Vqh7s?si=emDlvKViAzSPDVIc",
    },
    {
      id: "rrreYWD5_z8",
      title: "Que verrait on à la vitesse de la lumière? - Scienceclic",
      duration: "15min",
      imageUrl: "https://i.ytimg.com/vi/rrreYWD5_z8/hq720.jpg",
      link: "https://youtu.be/rrreYWD5_z8?si=KoF41E8dNObLvRHe",
    },
    {
      id: "vfEpotJyi8s",
      title: "Introduction au droit pénal général (cours de droit pénal)",
      duration: "21:28min",
      imageUrl: "https://i.ytimg.com/vi/HcTuxpJILQY/hq720.jpg",
      link: "https://www.youtube.com/watch?v=HcTuxpJILQY",
    },
    {
      id: "6Oc-X4px5a4",
      title: "Comprendre la physique quantique grâce au principe d'incertitude - Avec Étienne Klein",
      duration: "13min",
      imageUrl: "https://i.ytimg.com/vi/6Oc-X4px5a4/hq720.jpg",
      link: "https://youtu.be/6Oc-X4px5a4?si=ca2IM-_ZQrGnx9rS",
    },
  ];
  
// SuccessPage.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API } from '../apikeys';

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const planId = queryParams.get('planId');
    const userId = queryParams.get('userId');

    const handleSuccess = async () => {
      try {
        const response = await fetch(
          `${API}/payments/checkout-success?session_id=${sessionId}&planId=${planId}&userId=${userId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        // Check for network errors
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error from server:', errorData);
          throw new Error('Failed to process payment success');
        }

        const data = await response.json();

        if (data.error) {
          console.error('Server error:', data.error);
        } else {
          console.log('Payment successful!', data.user);
          // Redirect the user based on your application's requirements
          navigate('/'); // Example: Redirect to the home/dashboard page
        }
      } catch (error) {
        console.error('Error fetching success details:', error);
      }
    };

    if (sessionId && planId && userId) {
      handleSuccess();
    }
  }, [location, navigate]);

  return <div>Payment was successful! Redirecting...</div>;
};

export default SuccessPage;

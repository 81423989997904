import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allFlashCards: [],
};

const flashCardSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setFlashCards(state, action) {
      state.allFlashCards = action.payload;
    },
    clearFlashCard(state, action) {
      state.allFlashCards = []
    },
  },
});

export const { setFlashCards,clearFlashCard } = flashCardSlice.actions;

export default flashCardSlice.reducer;
// components/ExploreTopics.js
import React from "react";
import { videos } from "../data/videoData";
import { useTranslation } from "react-i18next";
import VideoCard from "./VideoCard";
// import { createNewThread } from "../apis/assistantApi";

const ExploreTopics = () => {
  const { t } = useTranslation();

  // const createThreads = async (videoId) =>{
  //   let threadId = await createNewThread({ type: "SUMMARY", videoId });
  // }
  return (
    <div className="w-full max-w-7xl">
      <h1 className="text-xl font-bold mb-4">{t("homepage.exploreTopics")}</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {videos.map((video) => (
          // <button onClick={() => createThreads(video.id)}>{video.id}</button>
          <VideoCard  item={{...video,thumbnailUrl:video.imageUrl,videoId:video.id}} />
        ))}
      </div>
    </div>
  );
};

export default ExploreTopics;

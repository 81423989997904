import React, { useEffect, useState } from "react";
import { useVideoData } from "../hooks/useVideoData";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MarkdownPreview from "./MarkdownPreview";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ExportOutlined, LoadingOutlined } from "@ant-design/icons";

// Skeleton component for loading summary sections
const SkeletonSummarySection = () => (
  <div className="mb-6 animate-pulse">
    <div className="h-5 bg-gray-700 rounded w-1/3 mb-3"></div>
    <div className="space-y-2">
      <div className="h-4 bg-gray-700 rounded w-full"></div>
      <div className="h-4 bg-gray-700 rounded w-5/6"></div>
      <div className="h-4 bg-gray-700 rounded w-3/4"></div>
    </div>
  </div>
);

const SummaryTab = () => {
  const { t } = useTranslation();
  const { videoId } = useParams();
  const { summary, fetchSummaryData } = useVideoData(videoId);
  const [isExporting, setIsExporting] = useState(false);

  const threads = useSelector((state) => state.assistant?.threads);
  const summaryThread = threads?.find((thr) => thr.type === "SUMMARY");

  const assistants = useSelector((state) => state.assistant?.assistants);
  const chatBotAssistant = assistants?.find(
    (assistant) => assistant.type === "SUMMARY"
  );

  useEffect(() => {
    const fetchSummary = async () => {
      await fetchSummaryData(
        summaryThread?.threadId,
        chatBotAssistant?.assistantId
      );
    };
    
    if(!summary){
      fetchSummary();
    }
  }, [summary]);

  // Export summary as PDF with padding
  const handleDownloadPDF = async () => {
    setIsExporting(true); // Show loading icon

    const summaryElement = document.getElementById("summary-content");
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.getHeight();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const padding = 10; // Padding for the content in mm

    try {
      const canvas = await html2canvas(summaryElement, {
        scale: 2, // Higher scale for better resolution
        backgroundColor: "#000000", // Ensures the black background matches the UI
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const imgWidth = pdfWidth - 2 * padding;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = padding;

      while (heightLeft > 0) {
        pdf.addImage(imgData, "PNG", padding, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        position = padding; // Reset position for next page
        if (heightLeft > 0) pdf.addPage();
      }

      pdf.save("summary.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsExporting(false); // Hide loading icon
    }
  };

  return (
    <div className="relative overflow-y-auto h-full bg-black rounded-lg px-4 pt-4">
      {/* Show export button only if summary is available */}
      {summary && (
        <button
          onClick={handleDownloadPDF}
          className="absolute top-2 right-2 text-gray-500"
          disabled={isExporting}
        >
          {isExporting ? <LoadingOutlined spin /> : <ExportOutlined />}
        </button>
      )}
      {!summary ? (
        <>
          <SkeletonSummarySection />
          <SkeletonSummarySection />
          <SkeletonSummarySection />
        </>
      ) : (
        <div
          id="summary-content"
          className="text-gray-300 bg-black p-4 rounded-lg"
        >
          <h1 className="text-2xl font-semibold text-white mb-4">Summary</h1>
          {typeof summary === "string" ? (
            <MarkdownPreview responseText={summary} />
          ) : (
            <p className="text-gray-400 text-center">
              {t("summary.noSummary")}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SummaryTab;

import axios from "axios";
import { API, YOUR_YOUTUBE_API_KEY } from "../apikeys";
import { message } from "antd";

// Helper function to get the user ID from local storage
const getUserId = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return user?.user.id; // Ensure you're accessing the correct property for the ID
};

// Axios instance with default headers
const axiosInstance = axios.create({
  headers: {
    "X-User-Id": getUserId(), // Set the user ID from local storage
  },
});

export const fetchVideoDetails = async (videoId) => {
  try {
    const response = await axiosInstance.get(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUR_YOUTUBE_API_KEY}&part=snippet,contentDetails,statistics`
    );
    return response.data.items[0];
  } catch (error) {
    throw new Error("Error fetching video details: " + error.message);
  }
};

export const fetchTranscript = async (videoId, threadId) => {
  try {
    const response = await axiosInstance.get(
      `${API}/transcript/${videoId}`
    );
    
    return response.data;
  } catch (error) {
    return error.response.data
    // throw new Error("Error fetching transcript: " + error.message);
  }
};

export const fetchTextFromPdf = async (videoId, threadId, file) => {
  try {
    const formData = new FormData();
    formData.append("videoId", videoId);
    formData.append("threadId", threadId);
    formData.append("pdfFile", file); // Attach the PDF file

    const response = await axios.post(
      `${API}/transcript/upload-pdf`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );


    return response.data;
  } catch (error) {
    throw new Error("Error fetching transcript: " + error.message);
  }
};

export const fetchSummary = async (transcript, videoId) => {
  if (transcript?.length > 120000) {
    message.error("You exceeded maximum character limit");
    return;
  }

  try {
    const response = await axiosInstance.post(`${API}/summarize`, {
      transcript,
      videoId,
    });
    return response.data.summary?.summary
      ? response.data.summary?.summary
      : response.data.summary;
  } catch (error) {
    throw new Error("Error fetching summary: " + error.message);
  }
};

export const generateChapters = async (transcript, videoId) => {
  if (transcript?.length > 120000) {
    message.error("You exceeded maximum character limit");
    return;
  }

  try {
    const response = await axiosInstance.post(`${API}/chapters`, {
      transcript,
      videoId,
    });
    return response.data.chapters;
  } catch (error) {
    throw new Error("Error generating chapters: " + error.message);
  }
};

export const fetchChapters = async (videoId) => {
  try {
    const response = await axiosInstance.get(`${API}/chapters/${videoId}`);
    return response.data.chapters;
  } catch (error) {
    throw new Error("Error fetching chapters: " + error.message);
  }
};

export const fetchChaptersStream = async (transcript, onData) => {
  try {
    const response = await axiosInstance.post(
      `${API}/chapters`, // Your API endpoint
      { transcript },
      {
        responseType: "stream", // Enable streaming response
      }
    );

    // Use a reader to process the stream incrementally
    const reader = response.data.getReader();
    const decoder = new TextDecoder("utf-8");
    let result = "";

    // Read the stream progressively
    while (true) {
      const { done, value } = await reader.read();
      if (done) break; // Exit the loop when streaming is done

      const chunk = decoder.decode(value, { stream: true });
      result += chunk; // Append chunk to result
      onData(result); // Pass the progressive result to the onData callback
    }

    return result; // Return the complete result at the end
  } catch (error) {
    throw new Error("Error fetching chapters: " + error.message);
  }
};

export const handleChat = async (transcript, chapters, messages, videoId) => {
  try {
    const response = await axiosInstance.post(`${API}/chat`, {
      transcript,
      chapters,
      messages,
      videoId,
    });
    return response.data.response;
  } catch (error) {
    throw new Error("Error sending chat message: " + error.message);
  }
};

export const handleChatByAssistant = async (
  transcript,
  chapters,
  messages,
  videoId
) => {
  try {
  } catch (error) {
    throw new Error("Error sending chat message: " + error.message);
  }
};

export const removeSpaceIdFromHistory = async (videoId) => {
  try {
    const response = await axiosInstance.put(`${API}/history/${videoId}`);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching: " + error.message);
  }
};

export const updateHistory = async (videoId, reqBody) => {
  try {
    const response = await axiosInstance.put(
      `${API}/history/${videoId}/edit`,
      reqBody
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching: " + error.message);
  }
};

export const fetchChats = async (videoId) => {
  try {
    const response = await axiosInstance.get(`${API}/chat/${videoId}`);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching: " + error.message);
  }
};

export const fetchHistoryByUserId = async () => {
  try {
    const response = await axiosInstance.get(`${API}/history`);
    return response.data.history;
  } catch (error) {
    throw new Error("Error fetching history: " + error.message);
  }
};

export const saveHistory = async (videoId, spaceId, type) => {
  try {
    const response = await axiosInstance.post(`${API}/history`, {
      videoId,
      spaceId,
      type,
    });
    return response.data;
  } catch (error) {
    throw new Error("Error saving history: " + error.message);
  }
};

export const saveHistoryForPdf= async (data) => {
  try {
    const response = await axiosInstance.post(`${API}/history/pdf`, data);
    return response.data;
  } catch (error) {
    throw new Error("Error saving history pdf: " + error.message);
  }
};

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setTranscript,
  setSummary,
  setChapters,
  setVideoId,
} from "../redux/videoSlice";
import {
  fetchSummary,
  fetchChapters,
  fetchTranscript,
  fetchTextFromPdf,
} from "../apis/youtubeAPI";
import { fetchSummaryPolling, runThread } from "../apis/assistantApi";
import { useSearchParams } from "react-router-dom";

export const useVideoData = (videoId) => {
  const dispatch = useDispatch();
  const transcript = useSelector(
    (state) => state.video?.transcript?.transcript
  );

  const summary = useSelector((state) => state.video?.summary);
  const chapters = useSelector((state) => state.video?.chapters);
  const storedVideoId = useSelector((state) => state.video?.videoId);

  let [searchParams, setSearchParams] = useSearchParams();
  let type = searchParams.get("type");

  const [loadingTranscript, setLoadingTranscript] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingChapters, setLoadingChapters] = useState(false);
  const [error, setError] = useState("");

  const [transcriptFetched, setTranscriptFetched] = useState(
    storedVideoId === videoId && !!transcript
  );
  const [summaryFetched, setSummaryFetched] = useState(
    storedVideoId === videoId && !!summary
  );
  const [chaptersFetched, setChaptersFetched] = useState(
    storedVideoId === videoId && !!chapters
  );

  // Fetch Transcript (only if not already in store or if videoId changes)
  useEffect(() => {
    const fetchData = async () => {
      if (storedVideoId !== videoId) {
        // Clear store if videoId changes
        dispatch(setTranscript(null));
        dispatch(setSummary(null));
        dispatch(setChapters(null));

        setTranscriptFetched(false);
        setSummaryFetched(false);
        setChaptersFetched(false);

        // Update the stored video ID
        dispatch(setVideoId(videoId));
      }

      if (!loadingTranscript && storedVideoId !== videoId) {
        setLoadingTranscript(true);
        try {
          let result = "";
          if (type === "PDF") {
            result = await fetchTextFromPdf(videoId, "", "");
          } else {
            result = await fetchTranscript(videoId);
          }
          dispatch(setTranscript(result));
          setTranscriptFetched(true);
        } catch (error) {
          setError("Error fetching transcript: " + error.message);
        } finally {
          setLoadingTranscript(false);
        }
      }
    };

    fetchData();
  }, [videoId, storedVideoId, transcriptFetched, loadingTranscript, dispatch]);

  // Fetch Summary (called only when needed and not available in store)
  const fetchSummaryData = async (threadId, assistantId) => {
    setLoadingSummary(true);
    try {
      const result = await runThread(threadId,videoId,assistantId,transcript);

      if (result.status === "completed") {
        setSummaryFetched(false);
        setLoadingSummary(false);
        dispatch(setSummary(result?.summary));
      }else{
        fetchSummaryData(threadId,assistantId)
      }
    } catch (error) {
      setError("Error fetching summary: " + error.message);
    } finally {
    }
  };

  // Fetch Chapters (called only when needed)
  const fetchChaptersData = async () => {
    if (chaptersFetched || loadingChapters) return; // If already fetched or loading, skip

    setLoadingChapters(true);
    try {
      const result = await fetchChapters(transcript, videoId);
      dispatch(setChapters(result));
      setChaptersFetched(true);
    } catch (error) {
      setError("Error fetching chapters: " + error.message);
    } finally {
      setLoadingChapters(false);
    }
  };

  return {
    transcript,
    summary,
    chapters,
    loadingTranscript,
    loadingSummary,
    loadingChapters,
    fetchSummaryData,
    fetchChaptersData,
    error,
    transcriptFetched,
    summaryFetched,
    chaptersFetched,
  };
};

import React, { useState } from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../config/firebaseConfig"; // Ensure you have Firebase configured
import { useNavigate } from "react-router-dom";
import { saveUser } from "../apis/userAPI";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/userSlice";
import GoogleIcon from "../assets/google.png"; // Add your Google icon here
import { message, Spin } from "antd";
import { useTranslation } from "react-i18next"; // Import the translation hook
import Spinner from "./Spinner";

const GoogleLogin = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    setIsLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Prepare user data
      const userData = {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
      };

      // Save user data using API
      const savedUser = await saveUser(userData);

      // Dispatch the user data to Redux store and save to localStorage
      dispatch(setUser(savedUser));
      window.location.reload();
    } catch (error) {
      message.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <div className="w-full max-w-xl mx-auto ">
        {/* <div className="mb-8">
          <h2 className="text-3xl text-white font-semibold mb-2 text-center" >
            {t("googleLogin.welcomeBack")}
          </h2>
        </div> */}

        <button
          onClick={handleGoogleLogin}
          className={`flex items-center justify-center w-full mb-6 bg-blue-500 text-white py-4 px-10 rounded-lg hover:bg-blue-600 transition duration-300 ${
            isLoading ? "opacity-50 cursor-not-allowed bg-gray-700" : ""
          }`}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner size="large" className="text-white" />
          ) : (
            <>
              <img
                src={GoogleIcon}
                alt="Google Icon"
                className="w-6 h-6 mr-2"
              />
              {t("googleLogin.signInWithGoogle")} {/* Translation key */}
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default GoogleLogin;

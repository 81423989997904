import axios from "axios";
import { API } from "../apikeys";
import { createNewThread, getAllAssistants } from "./assistantApi";

// Helper function to get the user ID from local storage
const getUserId = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.user?.id; // Ensure you're accessing the correct property for the ID
};

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor to set the header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const userId = getUserId();
    if (userId) {
      config.headers["X-User-Id"] = userId; // Set the user ID from local storage as a header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fetch space details by ID
export const fetchSpaceDetails = async (spaceId) => {
  try {
    const response = await axiosInstance.get(`${API}/spaces/${spaceId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching space details:", error);
    throw new Error("Error fetching space details: " + error.message);
  }
};

// Fetch space yt histoey by ID
export const fetchSpaceYtHistory = async (spaceId) => {
  try {
    const response = await axiosInstance.get(`${API}/history/${spaceId}/space`);
    return response.data.history;
  } catch (error) {
    console.error("Error fetching space YR details:", error);
    throw new Error("Error fetching space YR details: " + error.message);
  }
};

// Fetch items within a space
export const fetchSpaceItems = async (spaceId) => {
  try {
    const response = await axiosInstance.get(`${API}/spaces/${spaceId}/items`);
    return response.data;
  } catch (error) {
    console.error("Error fetching space items:", error);
    throw new Error("Error fetching space items: " + error.message);
  }
};

// Create a new space
export const createSpace = async (spaceData) => {
  try {
    const response = await axiosInstance.post(`${API}/spaces`, spaceData);

    await createNewThread({
      type: "SPACE_CHAT",
      videoId:response?.data?.id,
      transcript: "",
    });
    
    return response.data;
  } catch (error) {
    console.error("Error creating space:", error);
    return(error.response.data);
  }
};

// Update an existing space
export const updateSpace = async (spaceId, spaceData) => {
  try {
    const response = await axiosInstance.put(`${API}/spaces/${spaceId}`, spaceData);
    return response.data;
  } catch (error) {
    console.error("Error updating space:", error);
    throw new Error("Error updating space: " + error.message);
  }
};

// Delete a space by ID
export const deleteSpace = async (spaceId) => {
  try {
    const response = await axiosInstance.delete(`${API}/spaces/${spaceId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting space:", error);
    throw new Error("Error deleting space: " + error.message);
  }
};

// Fetch all spaces for the current user
export const fetchAllSpaces = async () => {
  try {
    const response = await axiosInstance.get(`${API}/spaces`);
    return response.data;
  } catch (error) {
    console.error("Error fetching spaces:", error);
    throw new Error("Error fetching spaces: " + error.message);
  }
};

import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./style/mk.css";

// Function to replace any content in square brackets with the Timestamp component
const transformMarkdown = (markdown) => {
  return markdown.replace(/\[([^\]]+)\]/g, (_, time) => {

    return `<Timestamp time="${time}" />`;
  });
};

const MarkdownPreview = ({ responseText }) => {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    // Ensure responseText is a string
    if (typeof responseText === "object") {
      setMarkdownContent(JSON.stringify(responseText, null, 2));
    } else {
      setMarkdownContent(transformMarkdown(responseText));
    }
  }, [responseText]);

  return (
    <div className="markdown-preview  text-white rounded-md shadow-lg mx-auto ">
      <ReactMarkdown
        children={markdownContent}
        remarkPlugins={[remarkGfm]}
        components={{
          Timestamp: ({ node, time }) => <Timestamp time={time} />
        }}
      />
    </div>
  );
};

export default MarkdownPreview;


// Timestamp component with a tooltip
const Timestamp = ({ time }) => (
  <span className="relative group">
    <span className="inline-flex items-center justify-center bg-gray-600 text-xs text-white rounded-full w-5 h-5">
      S
    </span>
    <span className="absolute left-1/2 transform -translate-x-1/2 -translate-y-8 bg-black text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
      {time}
    </span>
  </span>
);
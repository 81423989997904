import React, { useState } from "react";
import GoogleLogin from "../components/GoogleLogin";
import LogoIcon from "../assets/logo_icon.svg";
import { auth } from "../config/firebaseConfig";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { Divider, Input, Alert, Spin, Modal, message } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { setUser } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { saveUser } from "../apis/userAPI";

const SignUpSection = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const dispatch = useDispatch();

  const getFriendlyErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/email-already-in-use":
        return "An account with this email already exists. Please try logging in.";
      case "auth/invalid-email":
        return "The email address is not valid. Please enter a valid email.";
      case "auth/user-not-found":
        return "No account found with this email. Please sign up.";
      case "auth/wrong-password":
        return "The password is incorrect. Please try again.";
      case "auth/weak-password":
        return "Password is too weak. Please use a stronger password.";
      case "auth/invalid-credential":
        return "The credentials provided are invalid. Please try again.";
      default:
        return "An unexpected error occurred. Please try again later.";
    }
  };

  const handleLogin = async () => {
    setError("");
    setSuccess("");
    setLoading(true);
    if (!email || !password) {
      setError("Email and password cannot be empty.");
      setLoading(false);
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user.emailVerified) {
        console.log("User logged in successfully!");

        // Prepare user data
        const userData = {
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        };

        // Save user data using API
        const savedUser = await saveUser(userData);

        // Dispatch the user data to Redux store and save to localStorage
        dispatch(setUser(savedUser));
        window.location.reload();

        setSuccess("User logged in successfully!");
      } else {
        setError("Please verify your email before logging in.");
        await auth.signOut();
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError(getFriendlyErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    setError("");
    setSuccess("");
    setLoading(true);
    if (!email || !password) {
      setError("Email and password cannot be empty.");
      setLoading(false);
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);

      setSuccess(
        "Verification email sent. Please verify your email before logging in."
      );
      await auth.signOut();
      console.log("User signed up successfully and verification email sent.");
    } catch (error) {
      console.error("Error signing up:", error);
      setError(getFriendlyErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    setError("");
    setSuccess("");
    if (!email) {
      message.error("Please enter your email to reset the password.");
      setError("Please enter your email to reset the password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess("Password reset email sent. Please check your inbox.");
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setError(getFriendlyErrorMessage(error.code));
    }
  };

  const handleSubmit = () => {
    if (isSignUp) {
      handleSignUp();
    } else {
      handleLogin();
    }
  };

  return (
    <div className="flex flex-col p-8 h-full w-full bg-white rounded-md shadow-md">
      <div className="flex justify-center mb-6">
        <img src={LogoIcon} alt="Sumrly.ai Logo" className="h-24" />
      </div>
      <h2 className="text-lg font-medium text-gray-500 text-center mb-2">
        Welcome to <span className="text-blue-600">Sumrly.ai</span>
      </h2>

      <h1 className="text-2xl font-bold text-gray-800 text-center mb-6">
        {isSignUp ? "Sign up with your email" : "Login with your email"}
      </h1>

      {success && (
        <Alert
          message={success}
          type="success"
          showIcon
          className="text-center mb-4"
        />
      )}
      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          className="text-center mb-4"
        />
      )}

      <Input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        className="mb-4"
        size="large"
      />

      <Input.Password
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter your password"
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
        className="mb-2"
        size="large"
      />

      {!isSignUp && (
        <div className="text-right mb-4">
          <button
            onClick={() => setIsForgotPassword(true)}
            className="text-blue-500 hover:underline"
          >
            Forgot Password?
          </button>
        </div>
      )}

      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white font-semibold rounded-md p-2 mb-4 w-full hover:bg-blue-600 flex items-center justify-center"
        disabled={loading}
      >
        {loading ? (
          <Spin indicator={<LoadingOutlined style={{ color: "white" }} />} />
        ) : isSignUp ? (
          "Sign Up"
        ) : (
          "Login"
        )}
      </button>

      <div className="text-center text-gray-500 my-4">or</div>
      <GoogleLogin />

      <p className="text-sm text-gray-500 text-center mt-4">
        {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
        <button
          onClick={() => {
            setIsSignUp(!isSignUp);
            setError("");
            setSuccess("");
          }}
          className="text-blue-500 hover:underline"
        >
          {isSignUp ? "Login" : "Sign Up"}
        </button>
      </p>

      <Modal
        title="Reset Password"
        visible={isForgotPassword}
        onCancel={() => setIsForgotPassword(false)}
        onOk={handleForgotPassword}
        okText="Send Reset Email"
      >
        <p>Please enter your email address to receive a password reset link.</p>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
      </Modal>
    </div>
  );
};

export default SignUpSection;

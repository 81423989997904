import React, { useEffect, useState } from "react";
import { Button, Card, message, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { runThreadForQuizes } from "../apis/assistantApi";
import { saveUserAnswer } from "../apis/quizzesApi";
import { ExportOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Spinner from "./Spinner";
import "./style/QuizComponent.css";

const QuizComponent = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false); // For download indicator
  const { videoId } = useParams();
  const transcript = useSelector((state) => state.video?.transcript?.transcript);

  const assistants = useSelector((state) => state.assistant?.assistants);
  const quizBotAssistant = assistants?.find(
    (assistant) => assistant.type === "QUIZES"
  );

  const threads = useSelector((state) => state.assistant?.threads);
  const quizThread = threads?.find((thr) => thr.type === "QUIZES");

  useEffect(() => {

    
    const loadQuizData = async () => {
      setLoading(true);
      if (transcript ) {
        try {
          const res = await runThreadForQuizes(
            quizThread?.threadId,
            videoId,
            quizBotAssistant?.assistantId,
            transcript
          );

          if (res?.status === "completed" && !res.error) {
            const quizData = res.quizzes;
            setQuizzes(quizData);

            const initialAnswers = {};
            quizData.forEach((question, index) => {
              const userAnswerKey = Object.keys(question.userAnswers || {})[0];
              if (userAnswerKey) {
                initialAnswers[index] = question.userAnswers[userAnswerKey];
              }
            });
            setSelectedAnswers(initialAnswers);
          }
        } catch (error) {
          console.error("Error fetching quiz data:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    loadQuizData();
  }, []);

  const handleAnswerClick = async (questionIndex, option) => {
    const correctAnswer = quizzes[questionIndex]?.correct;

    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: option,
    }));

    try {
      await saveUserAnswer(videoId, questionIndex, option);
    } catch (error) {
      console.error("Error saving answer:", error);
      message.error("Failed to save answer. Please try again.");
    }
  };

  // Export as PDF function with title, padding, and multi-page support
  const handleExportPDF = async () => {
    setDownloading(true); // Show loading indicator
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const content = document.getElementById("quiz-content");

    // Add temporary style to ensure images render inline
    const style = document.createElement("style");
    style.innerHTML = "img { display: inline !important; }";
    document.head.appendChild(style);

    try {
      const totalHeight = content.scrollHeight;
      const pageHeightInPixels = (pdfHeight / 25.4) * 96; // Convert page height to pixels

      let yOffset = 0;
      let pageIndex = 0;

      while (yOffset < totalHeight) {
        content.scrollTop = yOffset;

        const canvas = await html2canvas(content, {
          scale: 2,
          y: yOffset,
          height: pageHeightInPixels,
          backgroundColor: "#000",
        });

        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pdfWidth - 20;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (pageIndex === 0) {
          pdf.setFontSize(16);
          pdf.setTextColor("#121212");
          pdf.text("Quiz Questions", pdfWidth / 2, 10, { align: "center" });
          pdf.addImage(imgData, "PNG", 10, 20, imgWidth, imgHeight);
        } else {
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
        }

        yOffset += pageHeightInPixels;
        pageIndex++;
      }

      pdf.save("quiz.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      message.error("Failed to export PDF. Please try again.");
    } finally {
      document.head.removeChild(style);
      setDownloading(false); // Hide loading indicator
    }
  };

  return (
    <div className="flex flex-col items-center p-2 bg-customGray min-h-screen text-white">
      {quizzes?.length > 0 && (
        <div className="relative w-full flex justify-end pr-6">
          <Button
            // icon={<ExportOutlined />}
            type="text"
            onClick={handleExportPDF}
            className="text-gray-500 text-xl hover:text-white"
            disabled={downloading} // Disable button while downloading
          >
            {downloading ? <Spinner size="small" /> : <ExportOutlined />}
          </Button>
        </div>
      )}
      
      {loading ? (
        <div className="flex items-center justify-center h-[70vh] w-full">
          <Spinner size="large" className="my-20" />
        </div>
      ) : (
        <div id="quiz-content" className="w-full space-y-4 px-4 rounded-lg p-2">
          {quizzes.map((quiz, index) => (
            <Card
              key={index}
              className="border-gray-800 border bg-gray-950 rounded-lg"
              bodyStyle={{ padding: "10px" }}
            >
              <p className="text-gray-300 text-lg font-semibold mb-4">{quiz.question}</p>
              <div className="flex flex-col space-y-3">
                {Object.entries(quiz.options).map(([optionKey, optionValue]) => (
                  <Button
                    key={optionKey}
                    type="default"
                    onClick={() => handleAnswerClick(index, optionKey)}
                    className={`flex items-center text-left px-5 py-4 rounded-lg w-full transition-all duration-200 transform hover:scale-105 ${
                      selectedAnswers[index] === optionKey
                        ? optionKey === quiz.correct
                          ? "correct-answer"
                          : "wrong-answer"
                        : "bg-customGray text-gray-200 hover:bg-gray-600 border border-gray-800"
                    }`}
                    style={{
                      justifyContent: "flex-start",
                      gap: "12px",
                    }}
                  >
                    <span className="font-bold text-lg">{optionKey.toUpperCase()}.</span>
                    <span className="text-left">{optionValue}</span>
                  </Button>
                ))}
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuizComponent;

import React from 'react'

export default function SkeletonChat({isUser}) {
  return (
    <div
      className={`flex flex-col mb-4 ${isUser ? "justify-end" : "justify-start"}`}
    >
      <div
        className={`p-3 rounded-lg animate-pulse mb-1 ${
          isUser ? "bg-gray-800" : "bg-gray-800"
        }`}
        style={{ width: "60%", minHeight: "24px" }}
      ></div>
  
      <div
        className={`p-3 rounded-lg animate-pulse mb-1 ${
          isUser ? "bg-gray-800" : "bg-gray-800"
        }`}
        style={{ width: "45%", minHeight: "24px" }}
      ></div>
  
      <div
        className={`p-3 rounded-lg animate-pulse ${
          isUser ? "bg-gray-800" : "bg-gray-800"
        }`}
        style={{ width: "60%", minHeight: "24px" }}
      ></div>
    </div>
  )
}



import React, { useState, useEffect, act } from "react";
import { fetchActivePlan, fetchAllPlans } from "../apis/planAPI";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { API } from "../apikeys";
import { getUserId, isMaxPremium } from "../utils/utils";
import Spinner from "./Spinner";

const SubscriptionPlans = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isBuying, setIsBuying] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const loadPlans = async () => {
      setLoading(true);
      try {
        const fetchedPlans = await fetchAllPlans();
        setPlans(fetchedPlans);

        setLoading(false);
      } catch (err) {
        setError(t("subscriptionPlans.errorFetchingPlans"));
        setLoading(false);
      }
    };
    loadPlans();
  }, []);

  const getFilteredPlans = () => {
    return plans
      .filter((item) => item.ln === "fr")
      .sort((a, b) => a.rank - b.rank) // Sort plans by rank
      .map((plan) => {
        if (isYearly && plan.yearlyPrice) {
          return {
            ...plan,
            price: plan.yearlyPrice,
            priceLabel: `${plan.yearlyPrice} ${t(
              "subscriptionPlans.perMonthAnnually"
            )}`,
          };
        } else {
          return {
            ...plan,
            price: plan.monthlyPrice,
            priceLabel: `${plan.monthlyPrice} ${t(
              "subscriptionPlans.perMonth"
            )}`,
          };
        }
      });
  };

  const handleCheckout = async (planId) => {
    const userId = getUserId();
    setIsBuying(true);
    try {
      const response = await fetch(`${API}/payments/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ planId, userId, isYearly }),
      });

      const { url } = await response.json();
      window.location.href = url; // Redirect to the Stripe checkout page
      setIsBuying(false);
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setLoading(false);

      setIsBuying(false);
    }
  };

  const handleBuyPlan = async (planId) => {
    handleCheckout(planId);
  };

  const renderSkeleton = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-8xl mx-auto">
      {[1, 2, 3].map((_, index) => (
        <div
          key={index}
          className="border border-gray-800 p-8 h-[500px] rounded-lg shadow-lg animate-pulse"
        >
          <div className="h-6 bg-gray-800 rounded mb-4 w-1/2"></div>
          <div className="h-4 bg-gray-800 rounded mb-4 w-3/4"></div>
          <div className="h-8 bg-gray-800 rounded mb-4 w-1/3"></div>
          <div className="space-y-2 mb-6 mt-4">
            {[1, 2, 3].map((_, idx) => (
              <div key={idx} className="h-4 bg-gray-800 rounded w-full"></div>
            ))}
          </div>
          <div className="h-10 bg-gray-800 rounded w-full mt-6"></div>
          <div className="space-y-2 mb-6 mt-6">
            {[1, 2, 3].map((_, idx) => (
              <div key={idx} className="h-4 bg-gray-800 rounded w-full"></div>
            ))}
          </div>
          <div className="h-10 bg-gray-800 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-800 rounded w-full"></div>
        </div>
      ))}
    </div>
  );

  const [disableAllPlans, setdisableAllPlans] = useState(true);

  useEffect(() => {
    if (getFilteredPlans()?.length > 0) {
        const check = async () =>{

          const isTrue = await isMaxPremium(plans)
          console.log(isTrue);
          
      if (isTrue) {
        setdisableAllPlans(true)
      } else {
        setdisableAllPlans(false);
      }
        }

        check()
    }
  }, [plans]);

  return (
    <div className="bg-black text-white py-16 px-4 sm:px-8 ">
      <h2 className="text-3xl text-center font-bold mb-12">
        {t("subscriptionPlans.header")}
      </h2>

      {/* Toggle Section */}
      <div className="flex justify-center   mb-8 ">
        <div className="border border-gray-700 py-2 px-2 rounded-lg flex flex-nowrap">
          <button
            className={` px-2 lg:px-6  py-1 lg:py-2 text-base font-medium rounded-lg mr-2 ${
              !isYearly ? "bg-customPink text-black" : ""
            }`}
            onClick={() => setIsYearly(false)}
          >
            {t("subscriptionPlans.payMonthly")}
          </button>
          <button
            className={` px-2 lg:px-6  py-1 lg:py-2 text-base font-medium rounded-lg ${
              isYearly ? "bg-customPink text-black" : ""
            }`}
            onClick={() => setIsYearly(true)}
          >
            {t("subscriptionPlans.payYearly")}
          </button>
        </div>
      </div>

      {loading ? (
        renderSkeleton()
      ) : error ? (
        <div className="text-center text-red-500 min-h-[40vh]">{error}</div>
      ) : getFilteredPlans().length === 0 ? (
        <div className="text-center text-gray-400 min-h-[40vh]">
          {t("subscriptionPlans.noPackages")}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-7xl mx-auto ">
          {getFilteredPlans().map((plan, index) => (
            <div
              key={index}
              className={`border-customPink p-8 bg-customGray rounded-lg shadow-lg border ${
                index === 1
                  ? "border-customPink bg-custom-gradient "
                  : "border-gray-700"
              }`}
            >
              <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
              <p className="text-gray-400 mb-4">{plan.description}</p>

              <div className="flex items-center justify-between ">
                <p className="text-3xl font-medium w-[50%] mb-2 mr-4">
                  € {plan.priceLabel}
                </p>

                {plan.discount > 0 && isYearly && (
                  <div className="bg-customPink  px-2 py-1 rounded-lg">
                    <p className="text-black text-sm font-medium">
                      Economisez {plan.discount}%
                    </p>
                  </div>
                )}
              </div>

              {/* {plan.price > 0 ? (
                <div className="flex items-center justify-between ">
                  <p className="text-3xl font-medium w-[50%] mb-2 mr-4">
                    € {plan.priceLabel}
                  </p>
                </div>
              ) : (
                <p className="text-green-500 mb-4">
                  {t("subscriptionPlans.freePlan")}
                </p>
              )} */}
              <p className="text-gray-500 text-xs text-extralight mb-4">
                {plan.billing}
              </p>

              <ul className="mb-6">
                {plan.features.map((feature, idx) => (
                  <li
                    key={idx}
                    className="text-gray-200 flex items-center mb-2"
                  >
                    <span className="mr-2 text-gray-200">✔️</span> {feature}
                  </li>
                ))}
              </ul>
              <button
                disabled={disableAllPlans || isBuying || plan.price === 0}
                className={`w-full flex items-center justify-center ${
                  disableAllPlans || isBuying || plan.price === 0
                    ? "bg-gray-500 cursor-default"
                    : "bg-white"
                } text-black py-4 text-lg rounded-lg hover:bg-gray-200  transition-all`}
                onClick={() => handleBuyPlan(plan.id)}
              >
                {isBuying ? (
                  <Spinner size="small" />
                ) : (
                  t("subscriptionPlans.buyNow")
                )}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlans;

import React from 'react';
import { Spin } from 'antd';
import { useVideoData } from '../hooks/useVideoData';

// Skeleton component for loading transcript items
const SkeletonTranscriptItem = () => (
  <div className="mb-2 p-2 rounded-lg animate-pulse ">
    <div className="h-3 bg-gray-700 rounded w-1/4 mb-2"></div>
    <div className="h-4 bg-gray-700 rounded w-full"></div>
  </div>
);

const TranscriptTab = ({ videoId, setTranscriptionData, playerRef }) => {
  const { transcript, loadingTranscript } = useVideoData(videoId);

  
  // Function to format the timestamp (seconds to mm:ss)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
  };

  // Function to handle clicking on a transcript item
  const handleTranscriptClick = (startTime) => {
    if (playerRef.current) {
      // Access the YouTube player iframe and use its API to seek to the specific time
      playerRef.current.contentWindow.postMessage(
        JSON.stringify({
          event: 'command',
          func: 'seekTo',
          args: [startTime, true]
        }),
        '*'
      );
    }
  };

  // Check if the transcript contains an error message
  const isErrorTranscript =false
  //  transcript && typeof transcript === 'string' && transcript.toLowerCase().includes('error');

  // Parse the transcript if available and not an error
  const parsedTranscript = transcript 

  
  return (
    <>
      {loadingTranscript ? (
        <div className="transcript-content overflow-y-auto h-[28vh] bg-black text-white p-4 rounded-lg space-y-2">
          <SkeletonTranscriptItem />
          <SkeletonTranscriptItem />
          <SkeletonTranscriptItem />
          <SkeletonTranscriptItem />
          <SkeletonTranscriptItem />
        </div>
      ) : (
        <div className="transcript-content overflow-y-auto h-[28vh] bg-black text-white rounded-lg">
          {isErrorTranscript ? (
            <p className="text-gray-400">No transcript available for this video.</p>
          ) : (
            parsedTranscript.length > 0 ? (
              parsedTranscript?.map((item, index) => (
                <div
                  key={index}
                  className="transcript-item  rounded-lg cursor-pointer border border-transparent py-6 px-4  hover:bg-customGray  "
                  onClick={() => handleTranscriptClick(item?.source)}
                >
                    <span className="text-sm text-gray-400">{formatTime(item?.source)}</span>
                
                  <p className="text-sm font-medium">{item?.page_content}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-400">No transcript available.</p>
            )
          )}
        </div>
      )}
    </>
  );
};

export default TranscriptTab;

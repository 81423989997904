import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  threads: [],
  assistants:[]
};

const threadsSlice = createSlice({
  name: 'assistant',
  initialState,
  reducers: {
    setThreads(state, action) {
      state.threads = action.payload;
    },
    setAssistants(state, action) {
      state.assistants = action.payload;
    },
    clearAssistants(state, action) {
      state.assistants = null
    },
    clearThreads(state) {
      state.threads = null;
      localStorage.removeItem('threads');
    },
  },
});

export const { setThreads, clearThreads,setAssistants,clearAssistants } = threadsSlice.actions;

export default threadsSlice.reducer;

import React, { useEffect, useState } from "react";
import { fetchHistoryByUserId } from "../apis/youtubeAPI";
import Header from "../components/Header";
import { Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VideoCard from "../components/VideoCard";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../redux/homeSlice";
import Spinner from "../components/Spinner";

const HistoryPage = ({ drawer, setIsDrawerOpen, count }) => {
  const [loading, setLoading] = useState(false);
  const { videoId } = useParams();
  const { t } = useTranslation(); // Translation hook
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const history = useSelector((state) => state.home?.history);

  const [historyLocal, setHistoryLocal] = useState(history || []);

  useEffect(() => {
    const fetchHistory = async () => {
      if (historyLocal?.length === 0) {
        setLoading(true);
      }
      try {
        const data = await fetchHistoryByUserId();
        setHistoryLocal(data);

        dispatch(setHistory(data));
      } catch (error) {
        console.error("Error fetching history:", error);
      } finally {
        setLoading(false);
      }
    };

    // if (historyLocal?.length === 0) {
    fetchHistory();
    // }
  }, []);

  if (history?.length === 0 && !loading) return <></>;

  return (
    <div className={`text-white ${!drawer && "min-h-screen bg-black"}`}>
      {!drawer && <Header />}
      <div className={`${!drawer && "p-10"}`}>
        <div className="flex items-center justify-between w-full">
          <h1 className="text-xl font-bold mb-4">
            {t("historyPage.watchHistory")}
          </h1>
          {count && (
            <h1
              onClick={() => navigate("/history")}
              className="text-sm  cursor-pointer font-bold mb-4"
            >
              {t("historyPage.viewMore")}
            </h1>
          )}
        </div>

        {loading ? (
          <div className="w-full h-[80vh] flex items-center justify-center ">
            <Spinner size="large" />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 ">
              {history.length > 0 ? (
                history
                  .slice(0, count ? count : history.length)
                  .map((item) => <VideoCard item={item} />)
              ) : (
                <p>{t("historyPage.noHistory")}</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HistoryPage;

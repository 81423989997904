import React, { useEffect, useState } from "react";
import { fetchActivePlan } from "../apis/planAPI";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import Spinner from "../components/Spinner";

export default function Settings() {
  const [activePlan, setActivePlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(); // Translation hook

  useEffect(() => {
    const loadPlan = async () => {
      try {
        const fetchedActivePlan = await fetchActivePlan();
        setActivePlan(fetchedActivePlan);
      } catch (activePlanError) {
        setActivePlan(null);
      }
      setLoading(false);
    };
    loadPlan();
  }, []);

  // Utility function to display "UNLIMITED" if value exceeds 1,000,000
  const formatValue = (value) => {
    return value > 1000000 ? t("settings.unlimited") : value;
  };

  const renderCustomTable = () => {
    if (!activePlan) return null;

    return (
      <div className="mt-12 bg-black border border-gray-800 text-gray-100 p-6 rounded-lg shadow-lg w-full">
        <h3 className="text-2xl font-semibold mb-6 text-center">
          {t("settings.activePlanTitle")}
        </h3>
        <table className="w-full border-collapse border border-gray-800 rounded-lg">
          <thead>
            <tr className="bg-gray-800">
              <th className="border border-gray-700 p-3 text-left text-gray-300">
                {t("settings.field")}
              </th>
              <th className="border border-gray-700 p-3 text-left text-gray-300">
                {t("settings.details")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-700 transition-colors">
              <td className="border border-gray-700 p-3">{t("settings.planName")}</td>
              <td className="border border-gray-700 p-3">
                {activePlan.planName || t("settings.notAvailable")}
              </td>
            </tr>
            <tr className="hover:bg-gray-700 transition-colors">
              <td className="border border-gray-700 p-3">{t("settings.description")}</td>
              <td className="border border-gray-700 p-3">
                {activePlan.description || t("settings.notAvailable")}
              </td>
            </tr>
            <tr className="hover:bg-gray-700 transition-colors">
              <td className="border border-gray-700 p-3">{t("settings.remainingSpace")}</td>
              <td className="border border-gray-700 p-3">
                {formatValue(activePlan?.details?.workspaces) ?? t("settings.notAvailable")}
              </td>
            </tr>
            <tr className="hover:bg-gray-700 transition-colors">
              <td className="border border-gray-700 p-3">{t("settings.remainingMessages")}</td>
              <td className="border border-gray-700 p-3">
                {formatValue(activePlan?.details?.messagesPerWeek) ?? t("settings.notAvailable")}
              </td>
            </tr>
            <tr className="hover:bg-gray-700 transition-colors">
              <td className="border border-gray-700 p-3">{t("settings.remainingFlashcards")}</td>
              <td className="border border-gray-700 p-3">
                {formatValue(activePlan?.details?.flashcards) ?? t("settings.notAvailable")}
              </td>
            </tr>
            <tr className="hover:bg-gray-700 transition-colors">
              <td className="border border-gray-700 p-3">{t("settings.remainingChapters")}</td>
              <td className="border border-gray-700 p-3">
                {formatValue(activePlan?.details?.videosOrPDFsPerWeek) ?? t("settings.notAvailable")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  if (loading)
    return (
      <div className="w-full h-full">
        <Header />
        <div className="h-[92vh] bg-black w-full pt-[110px] flex justify-center items-center">
          <Spinner size="large" />
        </div>
      </div>
    );

  return (
    <div className="bg-black min-h-screen">
      <Header />
      <div className="flex flex-col items-center w-full py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl w-full">
          {renderCustomTable()}
        </div>
      </div>
    </div>
  );
}

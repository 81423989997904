import React, { useState, useRef } from "react";
import TabsComponent from "./TabsComponent";
import TranscriptTab from "./TranscriptTab";
import ChaptersTab from "./ChaptersTab"; // Import ChaptersTab component
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the translation hook

const VideoSection = () => {
  const [leftTab, setLeftTab] = useState("chapters");
  const [chaptersGenerated, setChaptersGenerated] = useState(false); // State to track chapters generation
  const { videoId } = useParams();
  const { t } = useTranslation(); // Translation hook

  // Reference for the YouTube iframe player
  const playerRef = useRef(null);

  const [transcriptionData, setTranscriptionData] = useState("");

  const handleLeftTabChange = (tab) => {
    setLeftTab(tab);
  };

  const handleGenerateChapters = () => {
    setChaptersGenerated(true); // Set chaptersGenerated to true when button is clicked
  };

  const leftTabs = [
    { key: "chapters", label: t("videoSection.tabs.chapters") },
    { key: "transcripts", label: t("videoSection.tabs.transcripts") },
  ];

  return (
    <div className="w-full h-full lg:w-1/2 bg-black rounded-lg p-4">
      <div className="mb-6 h-[55%]">
        <iframe
          ref={playerRef}
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
          title={t("videoSection.videoTitle")}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="rounded-lg"
        ></iframe>
      </div>

      {/* Left Tabs */}
      <div className="h-[45%]">
        <TabsComponent
          tabs={leftTabs}
          activeTab={leftTab}
          onTabChange={handleLeftTabChange}
        />

        <div>
          {leftTab === "chapters" ? (
            <div className="bg-black border border-gray-700 p-4 rounded-lg mt-2">
              {/* {!chaptersGenerated ? (
                <button
                  onClick={handleGenerateChapters} // Call function to generate chapters
                  className="flex items-center space-x-2 bg-gray-700 p-2 rounded-lg text-white w-full justify-center"
                >
                  ✚ {t("videoSection.generateChapters")}
                </button>
              ) : (
                
              )} */}

              <ChaptersTab playerRef={playerRef} />
            </div>
          ) : (
            <div className="bg-black border border-gray-700 p-4 rounded-lg mt-2">
              <TranscriptTab
                videoId={videoId}
                setTranscriptionData={setTranscriptionData}
                playerRef={playerRef} // Pass playerRef to TranscriptTab
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoSection;

import React, { useState } from "react";
import CustomDeleteModal from "./CustomDeleteModal"; // Adjust path as needed
import { message } from "antd";
import { deleteSpace } from "../../apis/spaceAPI";

const DeleteSpaceModal = ({
  spaceId,
  spaceName,
  refreshSpaces,
  isVisible,
  closeModel,
}) => {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(isVisible);

  
  const handleDeleteSpace = async () => {
    setDeleteModalVisible(true);

    try {
      await deleteSpace(spaceId);
      message.success(`Space "${spaceName}" has been deleted.`);
      refreshSpaces(); // Refresh spaces after deletion
      setDeleteModalVisible(true);
      closeModel && closeModel()
    } catch (error) {
      message.error("Failed to delete space. Please try again.");
    } finally {
      setDeleteModalVisible(false);
    }
  };

  return (
    <>
      {!isVisible && (
        <button onClick={() => setDeleteModalVisible(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-trash h-4 w-4 hover:text-destructive hover:text-red-500 transition-all duration-200 hover:scale-105 flex-shrink-0"
          >
            <path d="M3 6h18"></path>
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
          </svg>
        </button>
      )}

      <CustomDeleteModal
        isVisible={isDeleteModalVisible}
        onClose={() => {
          if (closeModel) {
            closeModel();
            return;
          }
          setDeleteModalVisible(false);
        }}
        onDelete={handleDeleteSpace}
        spaceName={spaceName}
      />
    </>
  );
};

export default DeleteSpaceModal;

// components/ProtectedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // Get the user from the Redux store
  const user = useSelector((state) => state.user?.user);

  // If the user is not logged in, redirect to the login page
  return user ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;

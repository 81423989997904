import React, { useState } from "react";
import TabsComponent from "./TabsComponent";
import ChatSection from "./ChatSection";
import SummaryTab from "./SummaryTab";
import FlashCard from "../components/FlashCard";
import { useTranslation } from "react-i18next";
import QuizComponent from "./QuizComponent";

const RightSection = () => {
  const [activeTab, setActiveTab] = useState("chat");
  const { t } = useTranslation(); // Translation hook

  const handleRightTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Translated right tabs
  const rightTabs = [
    { key: "chat", label: t("rightSection.tabs.chat") },
    { key: "flashcards", label: t("rightSection.tabs.flashcards") },
    { key: "summary", label: t("rightSection.tabs.summary") },
    // { key: "quizes", label: "Quiz" },
  ];

  return (
    <div className="w-full lg:w-[50%] bg-black rounded-lg h-full  ">
      {/* Right Tabs */}
      <TabsComponent
        tabFullWidth
        tabs={rightTabs}
        activeTab={activeTab}
        onTabChange={handleRightTabChange}
      />

      {activeTab === "chat" && (
        <div className="bg-black py-2 px-2 lg:px-4 rounded-lg mt-4 h-[65vh] lg:h-[84vh] overflow-y-auto">
          <ChatSection />
        </div>
      )}
      {activeTab === "flashcards" && (
        <div className="bg-black p-2  rounded-lg mt-4 h-[65vh] lg:h-[81vh] overflow-y-auto">
          <FlashCard />
        </div>
      )}

      {activeTab === "summary" && (
        <div className="bg-black b rounded-lg mt-4 h-[65vh] lg:h-[81vh] overflow-y-auto">
          <SummaryTab />
        </div>
      )}

      {activeTab === "quizes" && (
        <div className="bg-black b rounded-lg mt-4 h-[65vh] lg:h-[81vh] overflow-y-auto">
          <QuizComponent />
        </div>
      )}
    </div>
  );
};

export default RightSection;

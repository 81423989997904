import React from "react";

const TabsComponent = ({ tabs, activeTab, onTabChange, tabFullWidth }) => {
  return (
    <div
      className={`flex ${
        tabFullWidth ? "w-full" : "justify-start w-[400px]"
      } px-1 bg-customGray rounded-xl py-1 space-x-4`}
    >
      {tabs.map((tab) => (
        <button
          key={tab.key}
          className={`rounded-xl px-2 py-1 transition-colors duration-300  ${
            activeTab === tab.key
              ? `${
                  tabFullWidth ? "bg-customPink text-black" : "bg-black"
                } `
              : " text-gray-400"
          } ${tabFullWidth ? "flex-1 text-center " : "w-full"}`}
          onClick={() => onTabChange(tab.key)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabsComponent;

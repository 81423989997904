import axios from "axios";
import { API } from "../apikeys";
import { getUserId } from "../utils/utils";

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor to set the header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const userId = getUserId();
    if (userId) {
      config.headers["X-User-Id"] = userId; // Set the user ID from local storage as a header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fetch quizzes for a specific video
export const fetchQuizzes = async (data) => {
  try {
    const response = await axiosInstance.post(`${API}/quizzes`, data);
    return response.data?.quizzes || [];
  } catch (error) {
    console.error("Error fetching quizzes:", error);
    return error.response.data;
  }
};

// Save user's answer for a question
export const saveUserAnswer = async (videoId, questionIndex, userAnswer) => {
    await axiosInstance.post(`${API}/quizzes/${videoId}/questions/${questionIndex}/answer`, {
      userAnswer,
    });
  };
import axios from 'axios';
import { API } from '../apikeys';

// Helper function to get the user ID from local storage
const getUserId = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.user?.id; // Ensure you're accessing the correct property for the ID
};

// Create an Axios instance with the user ID set as a default header
const axiosInstance = axios.create({
  baseURL: API,
  headers: {
    'X-User-Id': getUserId(), // Set the user ID from local storage
  },
});

// Save a new user
export const saveUser = async (userData) => {
  try {
    const response = await axiosInstance.post(`/users`, userData);
    return response.data;
  } catch (error) {
    console.error('Error saving user:', error);
    throw error;
  }
};

// Get a user by ID
export const getUserById = async () => {
  const user = JSON.parse(localStorage.getItem('user'));

  try {
    const response = await axiosInstance.get(`/users/${user?.user?.id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching user with ID ${user?.user?.id}:`, error);
    throw error;
  }
};

// Get all users
export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get(`/users`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all users:', error);
    throw error;
  }
};



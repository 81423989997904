import { fetchActivePlan, fetchAllPlans } from "../apis/planAPI";

export function secondsToMinutes(seconds) {
  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60); // Ensure seconds are within 0-59

  // Format the result as "hh:mm:ss" or "mm:ss" if no hours
  return hours > 0
      ? `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
      : `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
}

export const getUserId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.user?.id;
  };

  export const getUser = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.user;
  };


  export const isMaxPremium = async (plansData) => {
    const plans = plansData || await fetchAllPlans();
    // Fetch the active plan details
    const activePlan = await fetchActivePlan();

    // Get active plan's price based on isYearly selection
    const activePlanPrice = activePlan?.price;

    // Find the highest price among all plans
    const maxPrice = Math.max(
      ...plans.map((p) =>
        activePlan?.isYearly ? p.yearlyPrice : p.monthlyPrice
      )
    );

    // Disable all plans if active plan's price matches the max price
    return activePlanPrice >= maxPrice && activePlan.active;
  };
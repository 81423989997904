// apis/planAPI.js
import axios from "axios";
import { API } from "../apikeys";

// Helper function to get the user ID from local storage
const getUserId = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.user?.id; // Ensure you're accessing the correct property for the ID
};

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor to set the header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const userId = getUserId();
    if (userId) {
      config.headers["X-User-Id"] = userId; // Set the user ID from local storage as a header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fetch all plans
export const fetchAllPlans = async () => {
  try {
    const response = await axiosInstance.get(`${API}/plans`);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching plans: " + error.message);
  }
};

// Fetch plan by ID
export const fetchPlanById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API}/plans/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching plan: " + error.message);
  }
};

// Buy a plan
export const buyPlan = async (planId) => {
  try {
    const response = await axiosInstance.post(`${API}/subscriptions/buy`, {
      planId,
    });
    return response.data;
  } catch (error) {
    console.error("Error buying plan:", error);
    throw error;
  }
};

// Create a new plan
export const createPlan = async (planData) => {
  try {
    const response = await axiosInstance.post(`${API}/plans`, planData);
    return response.data;
  } catch (error) {
    console.error("Error creating plan:", error);
    throw error;
  }
};

// Update usage for a plan
export const updateUsage = async (userId, type, amount) => {
  try {
    const response = await axiosInstance.post(`${API}/plans/update-usage`, {
      userId,
      type,
      amount,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating usage:", error);
    throw error;
  }
};

// Fetch the active plan for the user
export const fetchActivePlan = async () => {
    try {
      const response = await axiosInstance.get(`${API}/plans/active/plan`);
      return response.data;
    } catch (error) {
      console.error('Error fetching active plan:', error);
      throw error;
    }
  };
  
// Check for plan expiry
export const checkPlanExpiry = async () => {
    try {
      const response = await axiosInstance.post(`${API}/subscriptions/check-expiry`);
      return response.data;
    } catch (error) {
      console.error('Error checking plan expiry:', error);
      throw error;
    }
  };

  
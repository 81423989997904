import React from "react";
import SubscriptionPlans from "../components/SubscriptionPlans";
import Header from "../components/Header";
import AutoAnimationScroll from "../components/AutoAnimationScroll";
import brown from "../assets/universities/brown.svg";
import centraleSup from "../assets/universities/Centrale sup logo.svg";
import emlyon from "../assets/universities/Emlyon.svg";
import ens from "../assets/universities/ENS logo.svg";
import escp from "../assets/universities/Escp logo.svg";
import essec from "../assets/universities/Esseclogo .svg";
import harvard from "../assets/universities/harvard.png";
import hec from "../assets/universities/Heclogo.svg";
import mit from "../assets/universities/mit.png";
import msu from "../assets/universities/msu.png";
import princeton from "../assets/universities/princeton.png";
import psllogo from "../assets/universities/PSLlogo.svg";
import skema from "../assets/universities/Skemalogo.svg";
import sorbonne from "../assets/universities/Sorbonnelogo.svg";
import stanford from "../assets/universities/stanford.svg";
import uofm from "../assets/universities/uofm.png";
import uparisSaclay from "../assets/universities/Uparissaclay logo.svg";
import upenn from "../assets/universities/upenn.svg";
import FAQ from "../components/FAQ";
import { useTranslation } from "react-i18next";

const row1 = [
  // brown,
  centraleSup,
  emlyon,
  ens,
  escp,
  essec,
  // harvard,
  hec,
  // mit,
  // msu,
  // princeton,
  psllogo,
  skema,
  sorbonne,
  // stanford,
  // uofm,
  uparisSaclay,
  // upenn,
];

const SubscriptionPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="h-[92vh] overflow-y-scroll">
        <SubscriptionPlans />
        <div className="w-full flex flex-col items-center justify-center bg-black pb-20">
          {/* First AutoAnimationScroll Component */}
          <h2 className="text-xs text-neutral-500 dark:text-neutral-400 mb-10">
            {t("faqs.trusted")}
          </h2>
          <AutoAnimationScroll images={row1} />

          <div className="mt-20">
            <FAQ />
          </div>
          {/* Second AutoAnimationScroll Component with Reverse Animation */}
          {/* <AutoAnimationScroll images={row2} reverse /> */}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;

import React, { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined, AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UpgradeModal from "./UpgradeModal";
import { useTranslation } from "react-i18next";
import { runThreadForFlashCard } from "../apis/assistantApi";
import { setFlashCards } from "../redux/flashCardSlice";
import "./style/FlashCard.css"; // Your existing CSS

const SkeletonChat = () => (
  <div className="flex flex-col mb-4 w-full">
    <div className="p-3 rounded-lg animate-pulse bg-[#0E0F14] w-full min-h-[60vh]"></div>
    <div className="flex items-center justify-between mt-10 px-20">
      <div className="p-6 rounded-lg animate-pulse bg-[#0E0F14] border border-gray-600"></div>
      <div className="p-6 rounded-lg animate-pulse bg-[#0E0F14] border border-gray-600"></div>
    </div>
  </div>
);

export default function FlashCard() {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [multiCardView, setMultiCardView] = useState(false);
  const [hintVisible, setHintVisible] = useState({});
  const [explanationVisible, setExplanationVisible] = useState({});
  const transcript = useSelector((state) => state.video?.transcript?.transcript);
  const { videoId } = useParams();

  const dispatch = useDispatch();
  const flashCards = useSelector((state) => state.flash?.allFlashCards);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(flashCards?.length === 0);
  const [popupMessage, setPopupMessage] = useState("");

  const assistants = useSelector((state) => state.assistant?.assistants);
  const chatBotAssistant = assistants?.find((assistant) => assistant.type === "FLASH_CARDS");

  const threads = useSelector((state) => state.assistant?.threads);
  let flashThread = threads?.find((thr) => thr.type === "FLASH_CARDS");

  const handlePopupClose = () => setShowPopup(false);

  const fetchAllFlashCards = async () => {
    const res = await runThreadForFlashCard(flashThread?.threadId, videoId, chatBotAssistant?.assistantId, transcript);
    if (res?.status === "completed") {
      setLoading(false);
      if (res.error) {
        setShowPopup(true);
        setPopupMessage(t("chaptersTab.limitError"));
        return;
      }
      dispatch(setFlashCards(res?.flashcards));
    }else{
      fetchAllFlashCards()
    }
  };

  useEffect(() => {
    if (flashCards?.length === 0) fetchAllFlashCards();
  }, [transcript, videoId]);

  const toggleView = (isMultiCard) => {
    setMultiCardView(isMultiCard);
    setCurrentIndex(0);
  };

  const handleFlip = (index) => {
    const updatedCards = flashCards.map((card, i) => ({
      ...card,
      isFlipped: i === index ? !card.isFlipped : card.isFlipped,
    }));
    dispatch(setFlashCards(updatedCards));
  };

  const handleHintClick = (index, e) => {
    e.stopPropagation();
    setHintVisible((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleExplanationClick = (index, e) => {
    e.stopPropagation();
    setExplanationVisible((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  if (loading) {
    return <SkeletonChat />;
  }

  return (
    <div className="flex flex-col items-center justify-center bg-black text-white p-4">
      {showPopup && <UpgradeModal message={popupMessage} onClose={handlePopupClose} visible={showPopup} />}

      {/* Toggle buttons */}
      <div className="flex justify-end w-full max-w-4xl mb-4">
        <button onClick={() => toggleView(false)} className={`px-2 py-1 rounded-lg ${!multiCardView ? "bg-gray-800" : ""}`}>
          <UnorderedListOutlined className="text-white" />
        </button>
        <button onClick={() => toggleView(true)} className={`px-2 py-1 rounded-lg ${multiCardView ? "bg-gray-800" : ""}`}>
          <AppstoreOutlined className="text-white" />
        </button>
      </div>

      {/* Multi-card view */}
      {multiCardView ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 w-full max-w-4xl">
          {flashCards.map((card, index) => (
            <div
              key={index}
              className={`relative border border-gray-800 bg-[#121212] overflow-y-scroll rounded-lg cursor-pointer flip-card-small ${card.isFlipped ? "flipped" : ""}`}
              onClick={() => handleFlip(index)}
            >
              <div className="flip-card-inner-small">
                <div className="flip-card-front-small flex flex-col items-center justify-center">
                  <h3 className="text-sm mb-2 text-center">{card.question}</h3>
                  <button
                    onClick={(e) => handleHintClick(index, e)}
                    className="text-xs text-gray-400 px-2 py-1 rounded-full hover:bg-gray-700"
                  >
                    {hintVisible[index] ? t("flashcard.hideHint") : t("flashcard.showHint")}
                  </button>
                  {hintVisible[index] && (
                    <p className="text-xs text-gray-400 mt-2 text-center" style={{ wordBreak: "break-word" }}>
                      {card.hint}
                    </p>
                  )}
                </div>
                <div className="flip-card-back-small flex flex-col items-center justify-center">
                  <h3 className="text-sm text-center mb-2">{card.answer}</h3>
                  <button
                    onClick={(e) => handleExplanationClick(index, e)}
                    className="bg-gray-800 px-3 py-1 rounded hover:bg-gray-700 mt-4 text-xs"
                  >
                    {explanationVisible[index] ? t("flashcard.hideExplanation") : t("flashcard.showExplanation")}
                  </button>
                  {explanationVisible[index] && (
                    <p className="text-xs text-center text-gray-400 mt-2" style={{ wordBreak: "break-word" }}>
                      {card.explanation}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        // Single-card view
        <div
          className={`relative border border-gray-800 w-full max-w-4xl h-[60vh] cursor-pointer rounded-lg overflow-hidden flip-card ${flashCards[currentIndex]?.isFlipped ? "flipped" : ""}`}
          onClick={() => handleFlip(currentIndex)}
        >
          <div className="flip-card-inner h-full">
            <div className="flip-card-front flex flex-col items-center justify-center w-full h-full p-6 rounded-lg">
              <div className="absolute top-2 right-2">
                <button
                  className="text-gray-400 text-xs px-2 py-1 rounded-full hover:bg-gray-700"
                  onClick={(e) => handleHintClick(currentIndex, e)}
                >
                  {hintVisible[currentIndex] ? t("flashcard.hideHint") : t("flashcard.showHint")}
                </button>
              </div>
              <h3 className="text-lg mb-4 text-center">{flashCards[currentIndex]?.question}</h3>
              {hintVisible[currentIndex] && (
                <p className="text-sm text-gray-400 mt-4 text-center">{flashCards[currentIndex]?.hint}</p>
              )}
            </div>
            <div className="flip-card-back flex flex-col items-center justify-center w-full h-full border border-gray-800 p-6 rounded-lg">
              <h3 className="text-lg text-center mb-4">{flashCards[currentIndex]?.answer}</h3>
              <button
                className="bg-gray-800 px-3 py-1 rounded hover:bg-gray-700 mt-4"
                onClick={(e) => handleExplanationClick(currentIndex, e)}
              >
                {explanationVisible[currentIndex] ? t("flashcard.hideExplanation") : t("flashcard.showExplanation")}
              </button>
              {explanationVisible[currentIndex] && (
                <p className="text-sm text-center text-gray-400 mt-4">{flashCards[currentIndex]?.explanation}</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Navigation for single-card view */}
      {!multiCardView && (
        <div className="flex items-center justify-between w-full px-4 mt-8">
          <button
            onClick={() => setCurrentIndex(Math.max(currentIndex - 1, 0))}
            className="border border-gray-700 h-10 w-10 p-2 rounded-lg hover:bg-gray-600 transition disabled:opacity-50"
          >
            <LeftOutlined />
          </button>
          <span className="text-sm">
            {currentIndex + 1} / {flashCards.length}
          </span>
          <button
            onClick={() => setCurrentIndex(Math.min(currentIndex + 1, flashCards.length - 1))}
            className="border border-gray-700 h-10 w-10 p-2 rounded-lg hover:bg-gray-600 transition disabled:opacity-50"
          >
            <RightOutlined />
          </button>
        </div>
      )}
    </div>
  );
}

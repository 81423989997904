import React, { useEffect, useState } from "react";
import { Button, Drawer, message, Spin, Popover } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { fetchHistoryByUserId } from "../apis/youtubeAPI";
import { createSpace, fetchAllSpaces } from "../apis/spaceAPI";
import UpgradeModal from "./UpgradeModal";
import { fetchActivePlan } from "../apis/planAPI";
import { useTranslation } from "react-i18next";
import { EllipsisOutlined } from "@ant-design/icons"; // Icon for vertical dots
import LanguageDropdown from "./LanguageDropdown";
import logo_icon from "../assets/logo_icon.svg";
import logo_text from "../assets/logo_text.svg";
import PlayIcon from "../assets/play_icon.svg";
import { getUser, getUserId } from "../utils/utils";
import Spinner from "./Spinner";
import { useDispatch, useSelector } from "react-redux";
import { setSpaces } from "../redux/homeSlice";
import DeleteSpaceModal from "./delete/DeleteSpaceModal";

const MySpaceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="lucide lucide-box flex-shrink-0 h-5 w-5"
  >
    <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path>
    <path d="m3.3 7 8.7 5 8.7-5"></path>
    <path d="M12 22V12"></path>
  </svg>
);

const Sidebar = ({ isVisible, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const spacesFromStore = useSelector((state) => state.home?.spaces);
  const historyFromStore = useSelector((state) => state.home?.history);

  const [history, setHistory] = useState(historyFromStore);
  const [activePlan, setActivePlan] = useState("");
  const { videoId } = useParams();
  const [count, setCount] = useState(4);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [spaces, setSpacesLocal] = useState(spacesFromStore || []);
  const [loading, setLoading] = useState(spacesFromStore?.length === 0);

  
const dispatch = useDispatch()
  const handleAddContentClick = () => {
    navigate("/");
    onClose();
  };

  const handleHistoryClick = () => {
    navigate("/history");
    onClose();
  };

  const handleUpgrade = () => {
    navigate("/pricing");
    onClose();
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.clear();
    window.location.reload();
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const data = await fetchHistoryByUserId();
        setHistory(data);
      } catch (error) {
        console.error("Error fetching history:", error);
      }
    };

    if (isVisible) {
      fetchHistory();
    }
  }, [isVisible]);

  const loadSpaces = async () => {
    try {
      const data = await fetchAllSpaces();
      setSpacesLocal(data);
      
      dispatch(setSpaces(data))
    } catch (error) {
      console.error("Error fetching spaces:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      loadSpaces();
    }
  }, [isVisible]);

  const handleSpaceClick = (spaceId) => {
    navigate(`/space/${spaceId}`);
    onClose();
  };

  useEffect(() => {
    const loadPlan = async () => {
      try {
        const fetchedActivePlan = await fetchActivePlan();
        setActivePlan(fetchedActivePlan?.planName);
      } catch (activePlanError) {
        setActivePlan(null);
      }
      setLoading(false);
    };
    if(isVisible){
      loadPlan();
    }
  }, [isVisible]);

  const user = getUser();

  const createSpaceHandler = async () => {
    setLoading(true);
    try {
      let res = await createSpace({
        name: t("sidebar.untitledSpace"),
        description: t("sidebar.noDescription"),
      });

      if (res.error) {
        setShowPopup(true);
        setPopupMessage(res.error);
        return;
      }
      message.success(t("sidebar.spaceCreated"));

      loadSpaces();
    } catch (error) {
      console.error("Error:", error.response.data);
    } finally {
      setLoading(false);
    }
  };

  // Custom popover content for logout and other actions
  const userActions = (
    <div className="flex flex-col">
      <button
        className="text-left px-2 py-1 hover:bg-gray-800 rounded"
        onClick={handleUpgrade}
      >
        {t("header.upgrade")}
      </button>
      <button
        className="text-left px-2 py-1 text-red-400 hover:border-gray-800 rounded"
        onClick={handleLogout}
      >
        {t("sidebar.logout")}
      </button>
    </div>
  );

  return (
    <Drawer
      placement="left"
      onClose={onClose}
      visible={isVisible}
      width={250}
      bodyStyle={{
        backgroundColor: "#171717",
        color: "#fff",
        padding: 0,
        borderRight: "1px solid #333",
      }}
      headerStyle={{ display: "none" }}
    >
      {showPopup && (
        <UpgradeModal
          message={popupMessage}
          onClose={handlePopupClose}
          visible={showPopup}
        />
      )}

      <div className="flex flex-col h-full  pb-8">
        {/* Logo Section */}
        <div
          className="flex items-center border-b border-gray-800 mb-4 pb-[6px]"
          onClick={() => navigate("/")}
        >
          <img
            src={logo_icon}
            className=" lg:block h-[50px] lg:ml-[-10px]"
            alt="Logo Icon"
          />
          <img
            src={logo_text}
            className="object-cover w-[160px]  ml-[-85px] block"
            alt="Logo Text"
          />
        </div>
        <div className="px-4 w-full">
          {/* Add Content Button */}
          <button
            className="flex items-center w-full justify-center px-3 py-2 border border-dotted border-gray-500 text-gray-400 rounded-lg mb-4 hover:text-white hover:border-white transition"
            onClick={handleAddContentClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
            </svg>
            {t("sidebar.addContent")}
          </button>
          {/* Recents Section */}
          <div className="mb-6">
            <h3 className="text-gray-100 mb-2">{t("sidebar.recents")}</h3>
            <div>
              {history.length > 0 ? (
                history.slice(0, 6).map((item) => (
                  <div
                    onClick={() => navigate(`/video/${item.videoId}`)}
                    key={item.id}
                    className={`flex cursor-pointer rounded-lg h-4 items-center px-2 py-4  ${
                      videoId === item.videoId
                        ? "bg-customPink text-black"
                        : "text-gray-300"
                    }`}
                  >
                    <div className="mr-2 w-4">
                      {videoId === item.videoId ? (
                        <div className="bg-green-600 p-1 h-3 w-3 rounded-full" />
                      ) : (
                        <div className="w-3 h-3">
                          <img src={PlayIcon} className="w-full h-full" />
                        </div>
                      )}
                    </div>

                    <p className="text-xs  truncate">{item.title}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-400 text-xs">
                  {t("sidebar.noHistory")}
                </p>
              )}
              {history.length > 6 && (
                <Button
                  onClick={() => navigate("/history")}
                  type="link"
                  className="text-gray-400 text-xs w-full"
                >
                  {t("sidebar.showMore")}
                </Button>
              )}
            </div>
          </div>




          {/* Spaces Section */}
          <div className="w-full mb-10">
            <h3 className="text-gray-100 mb-2">{t("sidebar.spaces")}</h3>
            <button
              disabled={loading}
              onClick={createSpaceHandler}
              className="w-full flex items-center justify-center px-3 py-2 border border-dotted border-gray-500 text-gray-400 rounded-lg mb-4 hover:text-white hover:border-white transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
              </svg>
              {t("sidebar.addSpace")}
            </button>

            {loading ? (
              <div className="flex justify-center items-center">
                <Spinner size="small" />
              </div>
            ) : spaces.length > 0 ? (
              <ul className="space-y-2">
                {spaces.map((space) => (
                <li
                  key={space.id}
                  className="flex items-center justify-between text-gray-400 p-2 rounded-lg hover:bg-gray-800"
                >
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => handleSpaceClick(space.id)}
                  >
                    <MySpaceIcon />
                    <span className="block ml-2">{space.name}</span>
                  </div>
                {/* Delete Modal */}
                <DeleteSpaceModal
                  spaceId={space?.id}
                  spaceName={space?.name}
                  refreshSpaces={loadSpaces} // Reload spaces after deletion
                />
                </li>
              ))}
              </ul>
            ) : (
              <p className="text-gray-400 text-xs">{t("sidebar.noSpaces")}</p>
            )}

            {spaces.length > 6 && (
              <Button
                onClick={() => setCount(count === 4 ? spaces.length : 4)}
                type="link"
                className="text-gray-400 text-xs w-full"
              >
                {count === 4 ? t("sidebar.showMore") : t("sidebar.hide")}
              </Button>
            )}
          </div>

          {/* Fixed Bottom Section */}
          <div className="px-4 pb-2 border-t border-gray-700 bg-customGray absolute bottom-0 left-0 right-0">
            <div className="w-full flex items-center justify-center mb-4">
              <LanguageDropdown openDirection="up" isFullWidth />
            </div>

            <div className="bg-customPink w-full text-black py-1 rounded-full text-center mb-2">
              {activePlan}
            </div>

            {/* Custom Popover for user actions */}
            <Popover content={userActions} trigger="click">
              <div className="flex justify-center items-center cursor-pointer text-gray-400">
                <span className="mr-2">{user?.email}</span>
                <EllipsisOutlined className="text-xl" />
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;

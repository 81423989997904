import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "./Spinner";
import TabsComponent from "./TabsComponent";
import { useTranslation } from "react-i18next";
import TranscriptTab from "./TranscriptTab";
import ChaptersTab from "./ChaptersTab";

const PdfSection = () => {
  const transcript = useSelector((state) => state.video?.transcript);
  const [leftTab, setLeftTab] = useState("pdf");
  const { t } = useTranslation(); // Translation hook
  const playerRef = useRef(null);

  const pdfUrl = transcript?.s3Url;

  const handleLeftTabChange = (tab) => {
    setLeftTab(tab);
  };

  const leftTabs = [
    { key: "pdf", label: t("videoSection.tabs.pdf") },
    { key: "chapters", label: t("videoSection.tabs.chapters") },
  ];

  return (
    <div className="w-full h-full lg:w-1/2 bg-black rounded-lg p-4">
      <div className="h-full">
        <TabsComponent
          tabs={leftTabs}
          activeTab={leftTab}
          onTabChange={handleLeftTabChange}
        />
        {leftTab === "chapters" ? (
          <div className="bg-black border border-gray-900 p-4 rounded-lg mt-2">
            <ChaptersTab height="h-[75vh]" playerRef={playerRef} />
          </div>
        ) : (
          <div className="bg-black h-[95%] border border-gray-700 rounded-lg mt-2">
            {pdfUrl ? (
              <div className="w-full h-full">
                <iframe
                  src={pdfUrl}
                  width="100%"
                  height="100%"
                  className="w-full h-full"
                  title="PDF Viewer"
                  frameBorder="0"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center w-full h-full">
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PdfSection;

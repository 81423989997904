import React, { useState } from "react";
import { Button, Spin } from "antd";
import Spinner from "../Spinner";

const WarningModel = ({ isVisible, title, content, tryAgain,onClose }) => {


  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-customGray bg-opacity-70 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative bg-[#171717] text-white p-6 rounded-lg max-w-xl w-full shadow-lg border border-gray-700"
        onClick={(e) => e.stopPropagation()} // Prevent click outside to close
        style={{
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
        }}
      >
        {/* Close Button */}
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-300"
          onClick={onClose}
          aria-label="Close"
        >
          <svg
            aria-hidden="true"
            fill="none"
            focusable="false"
            height="1em"
            role="presentation"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="1em"
          >
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </button>

        <div className="flex items-center">
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>

        <p className="text-gray-400 mb-6">{content}</p>

        {/* Bottom-right buttons */}
        <div className="flex justify-end mt-6 space-x-4">
          <Button
            onClick={() => {
              onClose();
              tryAgain();
            }}
            className="bg-gray-700 border-none text-white rounded-full px-4 py-1 hover:bg-gray-600"
            style={{
              borderRadius: "20px",
              padding: "0.5rem 1.5rem",
              fontWeight: "500",
            }}
          >
            Try Again
          </Button>
          <Button
            onClick={onClose}
            className="bg-white border-none text-customGray rounded-full px-4 py-1 hover:bg-red-700"
            style={{
              borderRadius: "20px",
              padding: "0.5rem 1.5rem",
              fontWeight: "500",
            }}
          >
            Go Back To Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WarningModel;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6 text-white font-sans">
      {/* FAQ Title */}
      <h2 className="text-3xl font-semibold text-center mb-2 text-gray-200">
        {t("faqs.faqTitle")}
      </h2>

      {/* Contact link */}
      <p className="text-center mb-8 text-gray-400">
        {t("faqs.faqContact")}{' '}
        
        <a href="mailto:fetnacirayan@gmail.com" className="text-gray-400 underline">{t("faqs.contact")}</a>

        </p>

      {/* FAQ Items */}
      <div className="space-y-2">
        {t("faqs.faqs", { returnObjects: true }).map((faq, index) => (
          <div key={index} className="border-b border-gray-700">
            {/* FAQ Question */}
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full flex justify-between items-center py-4 px-6 focus:outline-none transition-colors duration-300"
              style={{
                backgroundColor:  "transparent",
                color: "#E5E7EB",
                fontWeight: 500,
              }}
            >
              <span className="text-lg">{faq.question}</span>
              <span>{activeIndex === index ? "-" : "+"}</span>
            </button>

            {/* FAQ Answer with Tailwind CSS Animation */}
            <div
              className={`overflow-hidden transition-all duration-300 ease-in-out mb-2 ${
                activeIndex === index ? "max-h-[200px] " : "max-h-0 opacity-0"
              }`}
            >
              <div
                className="px-6 py-0 text-sm text-gray-300  rounded-lg"
              >
                <span className="block text-gray-400  mb-2">{faq.type}</span>
                {faq.answer || "No answer available."}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  maqData: [],
};

const maqSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setMAQ(state, action) {
      state.maqData = action.payload;
    },
    clearMAQ(state, action) {
      state.maqData = []
    },
  },
});

export const { setMAQ,clearMAQ } = maqSlice.actions;

export default maqSlice.reducer;
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChat, fetchChats } from "../apis/youtubeAPI";
import { useParams } from "react-router-dom";
import { message } from "antd";
import UpgradeModal from "../components/UpgradeModal";
import { useTranslation } from "react-i18next";
import QuestionCardList from "./QuestionCardList";
import { addChatMessage, setChatMessages } from "../redux/chatSlice";
import { addChatMessageToThread, runThreadForChat } from "../apis/assistantApi";
import MarkdownPreview from "./MarkdownPreview";
import Spinner from "./Spinner";
import SkeletonChat from "./skeleton/SkeletonChat";



// Function to clean and format message content with conditional bullet points and proper spacing
const cleanAndFormatMessage = (message) => {
  return (
    <div className="space-y-4">
      {message.split("\n").map((line, index) => {
        // Remove leading symbols (-, *, **) and trim spaces
        const cleanedLine = line
          .replace(/^[-*]+\s*/, "")
          .replace(/\*\*/g, "")
          .replace(/-/g, "")
          .trim();

        // Skip rendering if the line is empty after cleaning
        if (!cleanedLine) return null;

        // Replace *text* with bold styling
        const formattedLine = cleanedLine.replace(/\*(.*?)\*/g, "<b>$1</b>");

        // Detect nested levels by counting leading spaces and set padding dynamically
        const indentLevel = line.search(/\S|$/) / 2;
        const paddingLeft = indentLevel * 16; // Adjust padding per level for nested items

        // Only show bullet points for list items (lines starting with a hyphen, bullet, or star)
        const showBullet =
          line.trim().startsWith("-") || line.trim().startsWith("*");

        // Determine if the line is a heading (no indentation, larger font)
        const isHeading = indentLevel === 0;

        return (
          <div
            key={index}
            className={`flex items-center w-full`}
            style={{ paddingLeft: `${paddingLeft}px` }} // Dynamic padding for nested items
          >
            {showBullet && (
              <span className="mr-2 text-white block h-1 w-1 bg-white rounded-full" />
            )}
            <p
              className={`${
                isHeading ? "font-medium" : "font-light"
              } text-gray-200 w-full`}
              style={{
                fontSize: isHeading ? 16 : 16,
              }}
              dangerouslySetInnerHTML={{ __html: formattedLine }}
            ></p>
          </div>
        );
      })}
    </div>
  );
};

const ChatSection = () => {
  const { t } = useTranslation();
  const { videoId } = useParams();

  const transcript = useSelector(
    (state) => state.video?.transcript?.transcript
  );

  const chatContainerRef = useRef(null);
  const assistants = useSelector((state) => state.assistant?.assistants);
  const chatBotAssistant = assistants?.find(
    (assistant) => assistant.type === "CHAT_BOT"
  );
  const chatMessages = useSelector((state) => state.chat?.chatMessages);

  const [threadId, setThreadId] = useState(null);
  // const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRender, setLoadingRender] = useState(
    chatMessages?.length === 0
  );
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const threads = useSelector((state) => state.assistant?.threads);
  let chatThread = threads?.find((thr) => thr.type === "CHAT_BOT");
  const dispatch = useDispatch();

  // Initialize chatbot thread
  useEffect(() => {
    const initializeChatThread = async () => {
      setThreadId(chatThread?.threadId);
    };
    initializeChatThread();
  }, [chatThread]);

  const handleCardClick = (e) => {
    handleSubmit(e);
  };

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        if (transcript && videoId && chatMessages?.length === 0) {
          const response = await fetchChats(videoId);
          dispatch(setChatMessages(response?.chats));
        }
        setLoadingRender(false);
      } catch (error) {
        console.error("Error fetching chat history:", error);
        setLoadingRender(false);
      }
    };
    fetchChatHistory();
  }, [videoId, threadId]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  // Poll for responses from the assistant
  const pollForChatResponse = async (userMessage, messageId) => {
    if (!threadId) return;
    try {
      const response = await runThreadForChat(
        threadId,
        videoId,
        chatBotAssistant.assistantId,
        userMessage,
        messageId
      );

      if (response.status === "completed") {
        const assistantMessage = {
          role: "assistant",
          content: response.response,
        };
        dispatch(addChatMessage(assistantMessage));
        setLoading(false);
      } else {
        setTimeout(() => pollForChatResponse(userMessage, messageId), 1500); // Poll every 1.5 seconds for better UX
      }
    } catch (error) {
      console.error("Error polling assistant response:", error);
    }
  };

  const handleSubmit = async (_chatInput) => {
    if (loading || loadingRender) return;
    const _value = _chatInput || chatInput;
    if (_value?.trim()) {
      setLoading(true);
      const userMessage = { role: "user", content: _value?.trim() };
      dispatch(addChatMessage(userMessage));
      setChatInput("");

      try {
        const msgData = await addChatMessageToThread(threadId, {
          transcript: _value?.trim(),
          videoId,
          type: "CHAT_BOT",
        });

        pollForChatResponse(_value?.trim(), msgData?.messageId);
      } catch (error) {
        console.error("Error handling chat:", error);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (loading || loadingRender) return;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="flex flex-col h-full justify-between relative">
      {/* Chat Messages */}
      <div
        className="flex-grow overflow-y-auto py-4 text-white space-y-4 pb-[200px]"
        ref={chatContainerRef}
      >
        {loadingRender ? (
          <>
            <SkeletonChat isUser={true} />
          </>
        ) : (
          <>
            {chatMessages.length === 0 ? (
              <div className="text-center text-gray-500 min-h-[85%] flex items-center justify-center">
                {t("chatSection.noMessages")}
              </div>
            ) : (
              chatMessages.map((message, index) => (
                <div
                  key={index}
                  className={`mb-4 ${
                    message.role === "user" ? "text-right" : "text-left"
                  }`}
                >
                  <div
                    className={`inline-flex p-2 rounded-lg w-auto max-w-[80%] ${
                      message.role === "user"
                        ? "bg-customGray border border-gray-600 text-gray-200 text-left"
                        : "border border-gray-700 text-gray-300 text-sm font-light"
                    }`}
                  >
                    {message.role === "user" ? (
                      message.content
                    ) : (
                      <MarkdownPreview responseText={message.content} />
                    )}

                    {/* {cleanAndFormatMessage(message.content)} */}
                  </div>
                </div>
              ))
            )}
          </>
        )}

        {loading && (
          <div className="text-center py-4">
            <SkeletonChat isUser={false} />
          </div>
        )}
      </div>

      {/* Chat Input */}
      <div className="absolute bottom-0 left-0 right-0">
        <QuestionCardList onCardClick={handleCardClick} />

        <div className="px-4 pb-2 border-gray-700 bg-black">
          <div className="flex items-center space-x-2 border border-gray-600 rounded-full px-2">
            <textarea
              className={`flex-grow p-2 bg-transparent outline-none rounded-lg text-white focus:outline-none resize-none`}
              rows="1"
              placeholder={t("chatSection.placeholder")}
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              onKeyDown={handleKeyPress}
            ></textarea>
            <button
              disabled={loading || loadingRender}
              onClick={handleSubmit}
              className="bg-gray-900 text-white px-4 py-1 my-1 rounded-full disabled:opacity-50"
            >
              {loading || loadingRender ? <Spinner /> : "⌘K"}
            </button>
          </div>
        </div>
      </div>

      {/* Upgrade Modal */}
      <UpgradeModal
        visible={showUpgradeModal}
        message={t("chatSection.upgradeMessage")}
        onClose={() => setShowUpgradeModal(false)}
      />
    </div>
  );
};

export default ChatSection;

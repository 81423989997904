import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { message, Input, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import YouTubeInputField from "../components/YouTubeInputField";
import {
  saveHistory,
  removeSpaceIdFromHistory,
  updateHistory,
  saveHistoryForPdf,
} from "../apis/youtubeAPI";
import {
  fetchAllSpaces,
  fetchSpaceDetails,
  fetchSpaceYtHistory,
  updateSpace,
} from "../apis/spaceAPI";
import Spinner from "../components/Spinner";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import DropdownMenu from "../components/DropdownMenu"; // Import DropdownMenu component
import { useDispatch, useSelector } from "react-redux";
import { setSpaces } from "../redux/homeSlice";
import PreviewPdf from "../assets/preview_pdf_file.png";
import SpaceChat from "../components/SpaceChat";

const Space = () => {
  const { spaceId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [spaceDetails, setSpaceDetails] = useState({});
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [editingVideoId, setEditingVideoId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editingField, setEditingField] = useState(null);
  const [editedSpaceField, setEditedSpaceField] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dispatch = useDispatch();
  const spacesFromStore = useSelector((state) => state.home?.spaces);

  useEffect(() => {
    const loadSpaceDetails = async () => {
      try {
        const details = await fetchSpaceDetails(spaceId);
        setSpaceDetails(details);
      } catch (error) {
        console.error(t("space.errorFetchingDetails"));
      }
    };

    const getAllSpaces = async () => {
      const data = await fetchAllSpaces();
      dispatch(setSpaces(data));
    };

    if (spacesFromStore?.length === 0) {
      getAllSpaces();
    }
    loadSpaceDetails();
  }, [spaceId]);

  const loadSpaceHistoryYt = async () => {
    try {
      const details = await fetchSpaceYtHistory(spaceId);
      setHistory(details);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(t("space.errorFetchingDetails"));
    }
  };

  useEffect(() => {
    loadSpaceHistoryYt();
  }, [spaceId]);

  const handleTitleUpdate = async (videoId) => {
    if (editedTitle.trim() === "") {
      message.error(t("space.titleCannotBeEmpty"));
      return;
    }

    try {
      setUpdating(true);
      await updateHistory(videoId, { title: editedTitle });
      message.success(t("space.titleUpdatedSuccess"));
      setEditingVideoId(null);
      setLoading(true);
      loadSpaceHistoryYt();
    } catch (error) {
      message.error(t("space.errorUpdatingTitle"));
    } finally {
      setUpdating(false);
    }
  };

  const handleSpaceUpdate = async (field) => {
    if (
      editedSpaceField.trim() === "" ||
      editedSpaceField === spaceDetails[field]
    ) {
      setEditingField(null);
      return;
    }

    try {
      setUpdating(true);
      const updatedDetails = { ...spaceDetails, [field]: editedSpaceField };
      await updateSpace(spaceId, updatedDetails);
      message.success(t("space.fieldUpdatedSuccess", { field }));
      setEditingField(null);
      setSpaceDetails(updatedDetails);
    } catch (error) {
      message.error(t("space.errorUpdatingField", { field }));
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <Header />
      <div
        className="container mx-auto max-w-7xl pt-10 px-4 sm:px-6 lg:px-10"
        style={{ height: "92vh", overflowY: "auto" }}
      >
        <div className="w-full flex flex-col items-center justify-center">
          <h1 className="text-center font-normal lg:text-4xl sm:text-3xl text-xl leading-relaxed mb-8">
            {t("space.mainHeading")}
          </h1>
          <YouTubeInputField
            loading={loading}
            noRedirect
            onSubmit={async (videoId, data) => {
              setLoading(true);
              if (data?.type === "PDF") {
                await saveHistoryForPdf({
                  videoId,
                  ...data,
                  spaceId,
                });
              } else {
                await saveHistory(videoId, spaceId);
              }
              loadSpaceHistoryYt();
            }}
          />
        </div>

        <div className="bg-black border-b border-gray-700 flex flex-col sm:flex-row items-center justify-between mb-10">
          <div className="w-full sm:w-auto">
            {editingField === "name" ? (
              <Input
                value={editedSpaceField}
                onChange={(e) => setEditedSpaceField(e.target.value)}
                onPressEnter={() => handleSpaceUpdate("name")}
                onBlur={() => handleSpaceUpdate("name")} // Save on blur
                className="bg-transparent text-white"
                style={{
                  background: "black",
                  outline: "none",
                  border: "none",
                }}
                autoFocus
              />
            ) : (
              <div className="relative group flex w-full">
                <h2 className="text-2xl font-semibold mb-2">
                  {spaceDetails.name || t("space.unnamedSpace")}
                </h2>
                <EditOutlined
                  className="ml-4 opacity-0 group-hover:opacity-100 transition cursor-pointer"
                  onClick={() => {
                    setEditingField("name");
                    setEditedSpaceField(spaceDetails.name || "");
                  }}
                />
              </div>
            )}

            {editingField === "description" ? (
              <Input
                value={editedSpaceField}
                onChange={(e) => setEditedSpaceField(e.target.value)}
                onPressEnter={() => handleSpaceUpdate("description")}
                onBlur={() => handleSpaceUpdate("description")} // Save on blur
                className="bg-transparent text-white"
                style={{
                  background: "black",
                  outline: "none",
                  border: "none",
                }}
                autoFocus
              />
            ) : (
              <div className="relative group flex">
                <p className="text-gray-400">
                  {spaceDetails.description || t("space.noDescription")}
                </p>
                <EditOutlined
                  className="ml-4 opacity-0 group-hover:opacity-100 transition cursor-pointer"
                  onClick={() => {
                    setEditingField("description");
                    setEditedSpaceField(spaceDetails.description || "");
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col items-end mt-4 sm:mt-0">
            <div>
              <SpaceChat spaceDetails={spaceDetails}/>
              <button
                onClick={() => {
                  message.success(t("space.successCopyLink"));
                  navigator.clipboard.writeText(window.location.href);
                }}
                className="bg-customGray ml-2 border border-gray-700 text-white rounded-lg px-4 py-1 hover:bg-gray-700 transition"
              >
                {t("space.shareSpace")}
              </button>
            </div>
            <h3 className="text-sm font-extralight mt-4">
              {history.length} {t("space.item", { count: history.length })}
            </h3>
          </div>
        </div>

        <div className="mt-8">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-6">
            {history.map((item) => (
              <div
                key={item.id}
                className="border cursor-pointer border-gray-700 bg-customGray rounded-lg p-2 relative group overflow-hidden"
                onMouseEnter={() => setEditingVideoId(null)}
              >
                {/* Dropdown Button - Visible only on hover */}
                <div
                  className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"
                  onMouseEnter={() => setDropdownVisible(true)}
                  onMouseLeave={() => setDropdownVisible(false)}
                >
                  <DropdownMenu
                    isDelete
                    videoId={item.videoId}
                    historyId={item.id}
                    videoUrl={`${window.location.origin}/video/${item.videoId}`}
                    refreshHistory={() => loadSpaceHistoryYt()} // Pass refresh function
                  />
                </div>

                <img
                  src={item?.thumbnailUrl || PreviewPdf}
                  alt={item.title}
                  className="w-full h-40 object-cover rounded-lg border border-gray-700 mb-4 transition-transform duration-300 group-hover:scale-105"
                  onClick={() =>
                    navigate(
                      `/video/${item.videoId}?type=${item.type || "YOUTUBE"}`
                    )
                  }
                />
                <div className="p-2 flex justify-between items-center relative">
                  {editingVideoId === item.id ? (
                    <Input
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      onPressEnter={() => handleTitleUpdate(item.id)}
                      onBlur={() => {
                        if (editedTitle !== item.title)
                          handleTitleUpdate(item.id);
                      }} // Save on blur if different
                      className="bg-transparent text-white"
                      style={{
                        background: "black",
                        outline: "none",
                        border: "none",
                      }}
                      autoFocus
                    />
                  ) : (
                    <h4
                      className="text-sm font-medium truncate"
                      style={{ maxWidth: "150px" }}
                    >
                      {item.title}
                    </h4>
                  )}

                  {/* Edit button for title */}
                  <EditOutlined
                    className="absolute bottom-2 right-2 opacity-0 group-hover:opacity-100 transition cursor-pointer z-10"
                    onClick={() => {
                      setEditingVideoId(item.id);
                      setEditedTitle(item.title);
                    }}
                  />
                </div>

                {/* Green shadow effect on hover */}
                <div className="absolute bottom-0 left-0 right-0 h-2 bg-gradient-to-r from-transparent via-customPink to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
            ))}
          </div>
        </div>

        {!loading && history.length === 0 && (
          <div className="flex flex-col items-center justify-center w-full h-[30vh]">
            <h1>{t("space.addContentPrompt")}</h1>
            <p className="mt-4 text-gray-500">
              {t("space.learnSmarterMessage")}
            </p>
          </div>
        )}
      </div>
      {updating && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <Spinner size="large" />
        </div>
      )}
    </div>
  );
};

export default Space;

import React, { useState } from "react";
import { Menu, Dropdown, message, Spin } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSpaces } from "../apis/spaceAPI";
import { setSpaces } from "../redux/homeSlice";
import { removeSpaceIdFromHistory, saveHistory } from "../apis/youtubeAPI";
import { useTranslation } from "react-i18next";

const MySpaceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-box flex-shrink-0 h-4 w-4"
  >
    <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path>
    <path d="m3.3 7 8.7 5 8.7-5"></path>
    <path d="M12 22V12"></path>
  </svg>
);

const DropdownMenu = ({
  videoId,
  historyId,
  videoUrl,
  refreshHistory,
  isDelete,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const spacesFromStore = useSelector((state) => state.home?.spaces);
  const [loading, setLoading] = useState(false);
  const [loadingMove, setLoadingMove] = useState(false); // Loading state for move action
  const { t } = useTranslation();
  const { spaceId } = useParams();

  const handleCopy = () => {
    navigator.clipboard.writeText(videoUrl);
    message.success("URL copied to clipboard");
  };

  const handleMove = async (destinationSpaceId) => {
    setLoadingMove(true); // Start move loading
    try {
      await saveHistory(videoId, destinationSpaceId);
      refreshHistory();
      message.success("Moved successfully");
    } catch (error) {
      message.error("Failed to move");
    } finally {
      setLoadingMove(false); // End move loading
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await removeSpaceIdFromHistory(historyId);
      message.success(t("space.videoDeletedSuccess"));

      // Fetch updated spaces after deletion
      const data = await fetchAllSpaces();
      dispatch(setSpaces(data)); // Update Redux store with the new spaces
      refreshHistory(); // Refresh video list in the parent component
    } catch (error) {
      message.error(t("space.errorDeletingVideo"));
    } finally {
      setLoading(false);
    }
  };

  const renderMenu = (
    <Menu>
      <Menu.SubMenu
        key="move"
        title="Move"
        icon={<DragOutlined />}
        popupOffset={[0, -10]}
      >
        {loadingMove ? (
          <Menu.Item disabled>
            <Spin size="small" /> Moving...
          </Menu.Item>
        ) : spacesFromStore?.length > 0 ? (
          spacesFromStore
            ?.filter((flt) => flt.id !== spaceId)
            .map((spc) => (
              <Menu.Item key={spc.id} onClick={() => handleMove(spc.id)}>
                <MySpaceIcon /> {spc.name}
              </Menu.Item>
            ))
        ) : (
          <Menu.Item disabled>No spaces available</Menu.Item>
        )}
      </Menu.SubMenu>
      <Menu.Item key="copy" icon={<CopyOutlined />} onClick={handleCopy}>
        Copy
      </Menu.Item>
      {isDelete && (
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={handleDelete}
          danger
        >
          {loading ? <Spin size="small" /> : "Delete"}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={renderMenu} trigger={["click"]} placement="topRight">
      <div
        className="bg-gray-300 w-8 h-8 flex items-center justify-center rounded-lg cursor-pointer"
        onClick={(e) => e.stopPropagation()}
      >
        <MoreOutlined style={{ fontSize: "18px", color: "#000" }} />
      </div>
    </Dropdown>
  );
};

export default DropdownMenu;
